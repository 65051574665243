import { Component, Input, Output, EventEmitter } from '@angular/core';
import { OrderLine, GlobalSettings } from '../../../lib/lib';
import { ConfigurationService } from '../../../services/configuration/configuration.service';
import { OneLineArticleSaleMode } from '../../../lib/one-line-article-sale-mode';
import { LanguageService } from '../../../services/language.service';
import { StoreHistoryService } from '../../../services/store-history.service';
import { DisplayItemTypeEnum } from '../../../lib/display/display-item/display-item-type.enum';
import { AssetsService } from '../../../services/assets.service';

@Component({
  moduleId: module.id,
  selector: '[order-line]',
  templateUrl: 'order-line.component.html',
  styleUrls: ['order-line.component.scss'],
})
export class OrderLineComponent {
  @Input() orderLine: OrderLine;

  @Output()
  onOrderLineDeleted = new EventEmitter();

  constructor(
    public configurationService: ConfigurationService,
    private languageService: LanguageService,
    private storeHistoryService: StoreHistoryService,
    private assetsService: AssetsService,
  ) {
  }

  get canCountUp() {
    if (this.orderLine.ticketParameters && this.orderLine.ticketParameters.isQuantityEditable) {
      return false;
    }
    return this.orderLine.quantity < (this.orderLine.maxQuantity || 100);
  }

  get canCountDown() {
    if (this.orderLine.ticketParameters && this.orderLine.ticketParameters.isQuantityEditable) {
      return false;
    }
    return this.orderLine.quantity > (this.orderLine.minQuantity == 0 ? 0 : this.orderLine.minQuantity || 1);
  }

  get currencyCode() {
    return GlobalSettings.getCurrencyCode();
  }

  onClickCountChangeByOne(isUp: boolean) {
    if (isUp && this.canCountUp || !isUp && this.canCountDown) {
      this.orderLine.quantity += isUp ? 1 : -1;
    }
  }

  onClickDelete() {
    this.onOrderLineDeleted.emit(this.orderLine);
  }

  get partnerName(): string {
    if (!this.orderLine.ticketParameters) {
      return null;
    }

    return `${this.orderLine.ticketParameters.firstName} ${this.orderLine.ticketParameters.lastName}`;
  }

  get description(): string {
    return this.orderLine.description;
  }

  get groupSize(): number {
    if (!this.orderLine.ticketParameters) {
      return 0;
    }

    return this.orderLine.ticketParameters.groupSize;
  }

  get canDeleteLine(): boolean {
    return !this.orderLine.required;
  }

  get isControlForOrderLineRemoveVisible(): boolean {
    if (!this.canDeleteLine) {
      return false;
    }

    return this.configurationService.oneLineArticleSaleMode === OneLineArticleSaleMode.Disabled;
  }

  get areControlsForQuantityModifyVisible(): boolean {
    if (!this.canCountDown && !this.canCountUp) {
      return false;
    }

    return this.configurationService.oneLineArticleSaleMode !== OneLineArticleSaleMode.SingleArticle;
  }

  get productName(): string {
    return this.orderLine.translateName(this.language);
  }

  get language(): string {
    return this.languageService.odooLanguageName;
  }

  get attachmentUrl(): string {
    const productId = this.orderLine.productId;

    const gridTreeState = this.storeHistoryService.getLastGridTreeState();
    if (!gridTreeState) {
      return '';
    }

    const displayConfiguration = gridTreeState.displayConfiguration;
    if (!displayConfiguration) {
      return '';
    }

    const allItems = displayConfiguration.allItems;
    if (!allItems) {
      return '';
    }

    for (const item of allItems) {
      if (!item || item.type !== DisplayItemTypeEnum.Product
        || item.productId !== productId || !item.backgroundId) {
        continue;
      }

      const backgroundId = item.backgroundId;
      return this.assetsService.getAttachmentUrlById(backgroundId, this.languageService.getLanguage());
    }

    return '';
  }
}
