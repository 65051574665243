import { Injectable } from '@angular/core';

import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';
import { BaseGridWorkflowService } from '../base-grid-workflow.service';
import { DisplayItem } from '../../../../../lib/display/display-item/display-item';

@Injectable()
export class ServerActionGridWorkflowService extends BaseGridWorkflowService {

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.ServerAction;
  }

  processAction(displayItem: DisplayItem, context: any): boolean {
    const result = super.processAction(displayItem, context);
    if (!result || !displayItem || !displayItem.apiUrl) {
      return false;
    }

    this.navigateToUrlOrNavigationItem(displayItem);

    return true;
  }

  fillExternalApiRequestData(requestData: Map<string, any>, context: any) {
    if (context && context.odooContext) {
      requestData.set('odooContext', context.odooContext);
    }
  }
}
