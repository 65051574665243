import { Injectable, Injector } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { TranslateService } from '@ngx-translate/core';
import {
  GlobalSettings,
  DEFAULT_LANGUAGE_ID,
  Locale,
  Configuration,
  ENGLISH_LANGUAGE_ID,
  CZECH_LANGUAGE_ID,
  UKRAINIAN_LANGUAGE_ID,
  POLISH_LANGUAGE_ID,
  ROMANIAN_LANGUAGE_ID,
  FRENCH_LANGUAGE_ID,
  LOCALES,

} from '../lib/lib';
import { LoggingService } from './logging/logging.service';
import { MachineRootService } from './machines/machine-root.service';
import { DispatcherService } from './dispatcher.service';
import { MachineSaleShopService } from './machines/machine-sale.service';
import { ConfigurationService } from './configuration/configuration.service';
import { AdditionalPropertiesConfigurationService } from './configuration/additional-properties-configuration.service';

import { deLocale, enGbLocale, csLocale, plLocale, roLocale, frLocale } from 'ngx-bootstrap/locale';
import { uk } from '../lib/ngx-bootstrap-locales/locale';
import { defineLocale } from 'ngx-bootstrap';
import { registerLocaleData } from '@angular/common';
import localeCs from '@angular/common/locales/cs';
import localeDe from '@angular/common/locales/de';
import localePl from '@angular/common/locales/pl';
import localeUa from '@angular/common/locales/uk';
import localeFr from '@angular/common/locales/fr';
import localeRo from '@angular/common/locales/ro';

const COOKIE_KEY = 'locale';

export function getLanguageFactory(languageService: LanguageService) {
  return languageService.getLanguage();
}

@Injectable()
export class LanguageService {
  private languageTimer: any = null;
  private iSelectedLocale: Locale;
  private iDefaultLanguageId = DEFAULT_LANGUAGE_ID;
  private languageTimeout = 120000;
  private log: LoggingService;
  private machineRootService: MachineRootService;
  private dispatcherService: DispatcherService;
  private machineSaleShopService: MachineSaleShopService;
  private configurationService: ConfigurationService;
  private additionalPropertiesConfigurationService: AdditionalPropertiesConfigurationService;

  constructor(
    private translateService: TranslateService,
    private injector: Injector,
    private cookieService: CookieService,
  ) {
    this.dispatcherService = this.injector.get(DispatcherService);
    this.translateService.setDefaultLang(DEFAULT_LANGUAGE_ID);
    const languageId = this.cookieService.get(COOKIE_KEY);
    const id = languageId ? languageId : DEFAULT_LANGUAGE_ID;
    this.setSelectedLanguageById(id);
    this.dispatcherService.eventServiceInitialized.subscribe(() => this.eventServiceInitialized());
  }

  private eventServiceInitialized() {
    this.log = this.injector.get(LoggingService);
    this.machineRootService = this.injector.get(MachineRootService);
    this.machineRootService.eventStateChanged.subscribe(() => this.languageTimerRefresh());
    this.machineSaleShopService = this.injector.get(MachineSaleShopService);
    this.machineSaleShopService.eventStateChanged.subscribe(() => this.languageTimerRefresh());
    this.configurationService = this.injector.get(ConfigurationService);
    this.additionalPropertiesConfigurationService = this.injector.get(AdditionalPropertiesConfigurationService);
    this.dispatcherService.onConfigurationChangedSubscribe((x) => this._ConfigurationChanged(x));
  }

  private _ConfigurationChanged(configuration: Configuration) {
    this.languageTimeout = configuration.languageTimeout;
    const defaultLanguageId = this.defaultLanguageId;
    if (this.avalibleLocalesForCustomer && this.avalibleLocalesForCustomer.length !== 0
      && this.avalibleLocalesForCustomer.findIndex(x => x.id === defaultLanguageId) === -1) {
      // this.defaultLanguageId = this.avalibleLocalesForCustomer[0].id;
      this.selectedLocale = this.avalibleLocalesForCustomer[0];
    }
  }

  private languageTimerRefresh() {
    const isNotDefaultLocale = !this.isDefaultLocale(this.selectedLocale);
    if (isNotDefaultLocale && this.isMachineStateReadyToLanguageReset) {
      this.languageTimerStart();
    } else {
      this.languageTimerStop();
    }
  }

  private get isMachineStateReadyToLanguageReset(): boolean {
    let result = false;

    const rootMachineState = this.machineRootService == null ? null : this.machineRootService.state;
    const saleMachineState = this.machineSaleShopService == null ? null : this.machineSaleShopService.state;
    if (this.configurationService != null && this.configurationService.showArticlesOnMainPage) {
      result = ['sale', 'nonOperational'].includes(rootMachineState) && ['off', 'idle'].includes(saleMachineState);
    } else {
      result = ['idle', 'nonOperational'].includes(rootMachineState);
    }

    return result;
  }

  get locales(): Locale[] {
    return LOCALES;
  }

  get selectedLocale(): Locale {
    return this.iSelectedLocale;
  }

  set selectedLocale(value: Locale) {
    const languages = this.locales.filter(x => x === value);
    if (languages.length === 0) {
      this.log.error(`LanguageService. selectedLocale. locale not found: '${value}'`);
      return;
    }

    this.changeServerLanguage();

    if (this.iSelectedLocale === value) {
      return;
    }

    this.iSelectedLocale = value;
    this.cookieService.put(COOKIE_KEY, value.id);
    this.translateService.use(value.id);
    const scope = this;
    GlobalSettings.setCurrentLocale(this.iSelectedLocale.id);
    GlobalSettings.setTranslateFunc((x) => scope.translateService.instant(x));

    this.updateDefineLocale();

    if (this.dispatcherService) {
      this.dispatcherService.languageChanged();
    }

    this.languageTimerRefresh();
  }

  changeServerLanguage() {
    if (!this.dispatcherService || !this.dispatcherService.vuHttp) {
      return;
    }

    const localeId = this.getLanguage();
    this.dispatcherService.vuHttp.changeServerLanguage(localeId);
  }

  updateDefineLocale() {
    const localeId = this.getLanguage();
    switch (localeId) {
      case ENGLISH_LANGUAGE_ID:
        defineLocale(localeId, enGbLocale);
        break;
      case CZECH_LANGUAGE_ID:
        defineLocale(localeId, csLocale);
        registerLocaleData(localeCs, localeId);
        break;
      case UKRAINIAN_LANGUAGE_ID:
        defineLocale(localeId, uk);
        registerLocaleData(localeUa, localeId);
        break;
      case POLISH_LANGUAGE_ID:
        defineLocale(localeId, plLocale);
        registerLocaleData(localePl, localeId);
        break;
      case FRENCH_LANGUAGE_ID:
        defineLocale(localeId, frLocale);
        registerLocaleData(localeFr, localeId);
        break;
      case ROMANIAN_LANGUAGE_ID:
        defineLocale(localeId, roLocale);
        registerLocaleData(localeRo, localeId);
        break;
      default:
        defineLocale(localeId, deLocale);
        registerLocaleData(localeDe, localeId);
        break;
    }
  }

  setSelectedLanguageById(value: string) {
    const locale = this.getLocaleByLanguageId(value);
    if (locale == null) {
      return;
    }
    this.selectedLocale = locale;
  }

  getLocaleByLanguageId(value: string): Locale {
    const locales = this.locales.filter(x => x.id === value);
    if (locales.length > 0) {
      return locales[0];
    }

    this.log.error(`LanguageService. getLocaleByLanguageId. locale not found by: '${value}'`);
    return null;
  }

  set defaultLanguageId(value: string) {
    this.iDefaultLanguageId = value;
    if (this.log != null) {
      this.log.info(`LanguageService. set defaultLanguage: ${value}`);
    }
  }

  get defaultLanguageId(): string {
    return this.iDefaultLanguageId;
  }

  resetToDefaultLanguage() {
    this.setSelectedLanguageById(this.defaultLanguageId);
    if (this.log != null) {
      this.log.info(`LanguageService. resetToDefaultLanguage: ${this.defaultLanguageId}`);
    }
  }

  isDefaultLocale(value: Locale): boolean {
    const defaultLocale = this.getLocaleByLanguageId(this.defaultLanguageId);
    return defaultLocale != null && value === defaultLocale;
  }

  get odooLanguageName() {
    return Locale.convertToOdooLanguageId(this.getLanguage() || DEFAULT_LANGUAGE_ID);
  }

  getLanguage() {
    return this.iSelectedLocale.id;
  }

  private languageTimerStart() {
    this.languageTimerStop();
    const scope = this;
    this.languageTimer = setTimeout(() => {
      scope.resetToDefaultLanguage();
    }, this.languageTimeout);
  }

  private languageTimerStop() {
    if (this.languageTimer != null) {
      clearTimeout(this.languageTimer);
      this.languageTimer = null;
    }
  }

  get defaultLocale(): Locale {
    return this.getLocaleByLanguageId(this.defaultLanguageId);
  }

  get avalibleLocalesForCustomer(): Locale[] {
    const locales = this.locales;

    const supportLanguages = this.additionalPropertiesConfigurationService.supportLanguages;
    if (!supportLanguages || supportLanguages.length === 0) {
      return locales;
    }

    const filtredLocales = locales.filter(x => supportLanguages.includes(x.id));
    if (filtredLocales) {
      return filtredLocales;
    }

    return [this.defaultLocale];
  }
}
