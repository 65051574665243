import { Injectable } from '@angular/core';

import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';
import { DisplayItem } from '../../../../../lib/display/display-item/display-item';
import { BaseGridWorkflowService } from '../base-grid-workflow.service';

@Injectable()
export class DebugGridWorkflowService extends BaseGridWorkflowService {

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.Debug;
  }

  processAction(displayItem: DisplayItem, context: any): boolean {
    const result = super.processAction(displayItem, context);
    if (!result) {
      return false;
    }

    this.getDisplayItemInfo().then((data) => {
      this.showDataInDisplayGrid(data, context);
    });

    return true;
  }

  createTemplate(htmlData: string, backgroundColor: string) {
    const innerDiv = `<div class="grid-cell-container-inner">${htmlData}</div>`;
    return `<div class="grid-cell-container-middle" style="background: ${backgroundColor};">${innerDiv}</div>`;
  }

  getDisplayItemInfo(): Promise<any> {
    return new Promise((resolve, reject) => {
      const displayItemInfo = {
        items: [
          {
            id: 999000,
            name: 'Debug display item',
            sequence: 0,
            product_reference: false,
            pos_x: 1,
            pos_y: 1,
            size_horizontal: 57,
            size_vertical: 20,
            html_template: this.createTemplate('Debug Page 1</br>', 'orange'),
            type: 'category',
            background: -1,
          },
          {
            id: 999001,
            name: 'Debug-Item-11',
            sequence: 0,
            product_reference: false,
            pos_x: 1,
            pos_y: 1,
            size_horizontal: 57,
            size_vertical: 20,
            html_template: this.createTemplate('Debug Page 1</br>', 'orange'),
            type: 'static',
          },
          {
            id: 999002,
            name: 'Debug-Item-22',
            sequence: 0,
            product_reference: false,
            pos_x: 46,
            pos_y: 16,
            size_horizontal: 10,
            size_vertical: 3,
            html_template: this.createTemplate('<span style="color:green">@price</span>', 'lime'),
            type: 'category',
            background: -1,
            z_index: 10,
          },
          {
            id: 999011,
            name: 'Page 2',
            pos_x: 15,
            pos_y: 16,
            size_horizontal: 10,
            size_vertical: 3,
            html_template: this.createTemplate('Debug  go to Page 2', 'Wheat'),
            type: 'category',
            background: -1,
          },

        ],
        items_tree: [
          {
            item_id: 999000,
            items: [
              {
                item_id: 999001,
                items: []
              },
              {
                item_id: 999002,
                items: []
              },
              {
                item_id: 999011,
                items: [
                  {
                    item_id: 12,
                    items: []
                  },
                  {
                    item_id: 10,
                    items: []
                  },

                ]
              },
              {
                item_id: 14,
                items: []
              },
            ]
          },
        ]
      };
      resolve(displayItemInfo);
    });
  }
}
