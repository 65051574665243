import { Injectable } from '@angular/core';
import { SignalR, BroadcastEventListener, SignalRConnection, ConnectionStatus } from 'ng2-signalr';
import { VuConnectionBase } from './vu-connection-base.service';
import { Money, Ticket, VuState, OrderSaveResult, PrintTaskResult, DtoVuConfiguration } from '../../../lib/lib';
import { CreditCardTerminalEvent } from '../../../lib/credit-card/credit-card-terminal-event';
import { CIOBoardEvent } from '../../../lib/cioboard/coiboard-event';


@Injectable()
export class SignalRService extends VuConnectionBase {
  private signalR: SignalR;
  private reconnectTimeout = 10000;
  private connection: SignalRConnection;

  protected init() {
    let scope = this;
    this.signalR = this.injector.get(SignalR);
    setTimeout(() => scope._connect(), 1); // Let it be created before using the LoggingService
    super.init();
  }

  private _connect() {
    let scope = this;

    this.log.info('SignalRService. Starting...');

    this.connection = this.signalR.createConnection();

    this.connection.status.subscribe((status: ConnectionStatus) => {

      scope.isConneсted = status.name === 'connected';

      scope.eventConnectionChanged.emit(scope.isConneсted);

      let statusMessage = `SignalR connection status: ${status.name}`;
      this.log.info(statusMessage);
      statusMessage = `SignalR connection status: %c${status.name}`;

      switch (status.name) {
        case 'connected':
          console.log(statusMessage, 'color: green');
          break;
        case 'connecting':
          console.log(statusMessage, 'color: orange');
          break;
        case 'disconnected':
          console.log(statusMessage, 'color: red');
          setTimeout(() => {
            this.tryConnect();
          }, this.reconnectTimeout);
          break;
        default:
          // console.log(statusMessage);
          break;
      }
    });

    let vuStateChangedEvent = new BroadcastEventListener<VuState>('vuStateChanged');
    this.connection.listen(vuStateChangedEvent);
    vuStateChangedEvent.subscribe((state: VuState) => {
      state = VuState.fromOther(state);
      this.log.info(`vuStateChanged event: ${state}`);
      this.eventVuStateChanged.emit(state);
    });

    let navigatePageEvent = new BroadcastEventListener<string>('navigatepage');
    this.connection.listen(navigatePageEvent);
    navigatePageEvent.subscribe((navigateUrl: string) => {
      this.navigationUrlChanged.emit(navigateUrl);
      this.log.info(`Navigate to: ${navigateUrl}`);
    });

    let scanTicketEvent = new BroadcastEventListener<any>('scancard');
    this.connection.listen(scanTicketEvent);
    scanTicketEvent.subscribe((ticket: any) => {
      let t = Ticket.fromJson(ticket);
      super.scanTicket(t);
    });

    let barcodeReadEvent = new BroadcastEventListener<string>('barcodeRead');
    this.connection.listen(barcodeReadEvent);
    barcodeReadEvent.subscribe((barcode: string) => {
      super.barcodeRead(barcode);
    });

    let rfidCardReadEvent = new BroadcastEventListener<string>('rfidCardRead');
    this.connection.listen(rfidCardReadEvent);
    rfidCardReadEvent.subscribe((rfidCardCode: string) => {
      super.rfidCardRead(rfidCardCode);
    });

    let eventMoneyChanged = new BroadcastEventListener<Money>('moneyChanged');
    this.connection.listen(eventMoneyChanged);
    eventMoneyChanged.subscribe((money: Money) => {
      this.eventMoneyChanged.emit(new Money(money.value, money.currencyCode));
      this.log.info(`moneyChanged event: ${JSON.stringify(money)}`);
    });

    let eventReturnAmountFinished = new BroadcastEventListener<Money>('returnAmountFinished');
    this.connection.listen(eventReturnAmountFinished);
    eventReturnAmountFinished.subscribe(() => {
      super.returnAmountFinished();
    });

    let eventReturnChangeFinished = new BroadcastEventListener<Money>('returnChangeFinished');
    this.connection.listen(eventReturnChangeFinished);
    eventReturnChangeFinished.subscribe(() => {
      super.returnChangeFinished();
    });

    let eventSaveOrderResultReceived = new BroadcastEventListener<OrderSaveResult>('saveOrderResult');
    this.connection.listen(eventSaveOrderResultReceived);
    eventSaveOrderResultReceived.subscribe((saveOrderResult: OrderSaveResult) => {
      let result = OrderSaveResult.fromOther(saveOrderResult);
      this.eventOrderSaveResultReceived.emit(result);
      this.log.info(`eventSaveOrderResultReceived: ${JSON.stringify(saveOrderResult)}`);
    });

    let eventPrintTaskResultReceived = new BroadcastEventListener<PrintTaskResult>('printTaskResult');
    this.connection.listen(eventPrintTaskResultReceived);
    eventPrintTaskResultReceived.subscribe((printTaskResult: PrintTaskResult) => {
      let result = PrintTaskResult.fromOther(printTaskResult);
      this.eventPrintTaskResultReceived.emit(result);
      this.log.info(`eventPrintTaskResultReceived: ${JSON.stringify(printTaskResult)}`);
    });

    let eventTicketRemoved = new BroadcastEventListener<PrintTaskResult>('onTicketRemoved');
    this.connection.listen(eventTicketRemoved);
    eventTicketRemoved.subscribe((printTaskResult: PrintTaskResult) => {
      let result = PrintTaskResult.fromOther(printTaskResult);
      this.eventPrintTicketRemoved.emit(result);
      this.log.info(`eventTicketRemoved: ${JSON.stringify(printTaskResult)}`);
    });

    let eventVuConfigurationChanged = new BroadcastEventListener<any>('vuConfigurationChanged');
    this.connection.listen(eventVuConfigurationChanged);
    eventVuConfigurationChanged.subscribe(() => {
      super.vuConfigurationChanged();
    });

    let eventOnTurnstileEnter = new BroadcastEventListener<Money>('onTurnstileEnter');
    this.connection.listen(eventOnTurnstileEnter);
    eventOnTurnstileEnter.subscribe(() => {
      super.onTurnstileEnter();
    });

    let eventOnTicketRemoved = new BroadcastEventListener('onTicketRemoved');
    this.connection.listen(eventOnTicketRemoved);
    eventOnTicketRemoved.subscribe(() => {
      super.onTicketRemoved();
    });

    let eventOnInvalidRfidCardInserted = new BroadcastEventListener('onInvalidRfidCardInserted');
    this.connection.listen(eventOnInvalidRfidCardInserted);
    eventOnInvalidRfidCardInserted.subscribe(() => {
      super.onInvalidRfidCardInserted();
    });

    let eventCreditCardTerminalEvent = new BroadcastEventListener<any>('onCreditCardTerminalEvent');
    this.connection.listen(eventCreditCardTerminalEvent);
    eventCreditCardTerminalEvent.subscribe((x: any) => {
      const creditCardTerminalEvent = CreditCardTerminalEvent.fromOther(x);
      super.onCreditCardTerminalEvent(creditCardTerminalEvent);
    });

    let eventGateTransactionBegin = new BroadcastEventListener('onGateTransactionBegin');
    this.connection.listen(eventGateTransactionBegin);
    eventGateTransactionBegin.subscribe((x: boolean) => {
      super.onGateTransactionBegin(x);
    });

    let eventGateTransactionEnd = new BroadcastEventListener('onGateTransactionEnd');
    this.connection.listen(eventGateTransactionEnd);
    eventGateTransactionEnd.subscribe((x: boolean) => {
      super.onGateTransactionEnd(x);
    });

    let eventBnaValidationStarted = new BroadcastEventListener('onBnaValidationStarted');
    this.connection.listen(eventBnaValidationStarted);
    eventBnaValidationStarted.subscribe(() => {
      super.onBnaValidationStarted();
    });

    let eventBnaValidationFinished = new BroadcastEventListener('onBnaValidationFinished');
    this.connection.listen(eventBnaValidationFinished);
    eventBnaValidationFinished.subscribe(() => {
      super.onBnaValidationFinished();
    });

    const eventCIOBoardStateChanged = new BroadcastEventListener('onCIOBoardStateChanged');
    this.connection.listen(eventCIOBoardStateChanged);
    eventCIOBoardStateChanged.subscribe((x: any) => {
      const cioBoardEvent = CIOBoardEvent.fromOther(x);
      super.onCIOBoardStateChanged(cioBoardEvent);
    });

    this.connection.errors.subscribe(error => {
      this.log.error(error.message || error);
    });

    this.tryConnect();
  }

  private tryConnect() {
    try {
      this.connection.start().catch(error => {
        this.log.error(error);
      });
    } catch (error) {
      this.log.error(error);
    }
  }

}
