import { Injectable } from '@angular/core';

import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';
import { BaseGridWorkflowService } from '../../base/base-grid-workflow.service';
import { Subscription } from 'rxjs';
import { SaleService } from '../../../../sale.service';
import { IVuHttp } from '../../../../vu/http/vu-http.interface';
import { DisplayItem } from '../../../../../lib/display/display-item/display-item';
import { Money } from '../../../../../lib/lib';

@Injectable()
export class CreateOrderGridWorkflowService extends BaseGridWorkflowService {

  subsrcription: Subscription;
  private saleService: SaleService;
  private vuHttp: IVuHttp;

  private basketJson: any;
  private basket: any;
  private basketAmount: number;
  private productId: number;
  private currency: string;

  init(
  ) {
    super.init();
    this.saleService = this.injector.get(SaleService);
    this.vuHttp = this.vuCommunicationService.vuHttp;
  }

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.CreateOrder;
  }


  show(displayItem: DisplayItem, context: any): void {
    if (!context
      || !context.hasOwnProperty('odooContext')
      || !context.odooContext.hasOwnProperty('basket_json')
      || !context.odooContext.hasOwnProperty('basket_amount')
      || !context.odooContext.hasOwnProperty('basket_product_id')) {
      return;
    }

    this.parseContext(context);
    this.addCustomerDataToContext(context);

    if (!this.saleService.remoteTransaction.isOpened) {
      this.addProductToOrder();
    }
  }

  addProductToOrder() {
    const scope = this;
    scope.vuHttp.getProduct(this.productId).then(
      (result) => {
        const product = result;
        const price = new Money(this.basketAmount, this.currency);
        scope.saleService.resetOrder();
        scope.saleService.order.addProduct(product, 1, price);
        scope.saleService.order.updateProperties('order_json_data', this.basketJson);
      }
    );
  }

  addCustomerDataToContext(context: any) {
    if (this.basket === undefined) {
      return;
    }
    const customer = this.basket.hasOwnProperty('customer') ? this.basket.customer : false;
    const firstName = customer && customer.hasOwnProperty('firstName') ? customer.firstName : '';
    const lastName = customer && customer.hasOwnProperty('lastName') ? customer.lastName : '';

    if (!context.odooContext.hasOwnProperty('externalContext')) {
      context.odooContext['externalContext'] = new Object();
    }

    Object.assign(context.odooContext.externalContext,
      {
        'firstName': firstName,
        'lastName': lastName,
      });
  }

  parseContext(context: any) {
    this.basketJson = context.odooContext.basket_json;
    this.basket = JSON.parse(this.basketJson);
    this.basketAmount = context.odooContext.basket_amount;
    this.productId = context.odooContext.basket_product_id;
    this.currency = this.basket.hasOwnProperty('currency') ? this.basket.currency : '';
  }
}
