import { Injectable, Injector } from "@angular/core";
import { ConfigurationService } from "../configuration/configuration.service";
import { VuCommunicationService } from "../vu/vu-communication.service";
import { SaleService } from "../sale.service";
import { AdditionalPropertiesConfigurationService } from "../configuration/additional-properties-configuration.service";
import { CreditCardTerminalEvent } from '../../lib/credit-card/credit-card-terminal-event';
import { CreditCardTerminalEventType } from '../../lib/credit-card/credit-card-terminal-event-type';

@Injectable()
export class DemoPaymentService {
  private configurationService: ConfigurationService;
  private vuCommunicationService: VuCommunicationService;
  private saleService: SaleService;
  private additionalPropertiesConfigurationService: AdditionalPropertiesConfigurationService;

  constructor(
    protected injector: Injector,
  ) {
    this.configurationService = this.injector.get(ConfigurationService);
    this.vuCommunicationService = injector.get(VuCommunicationService);
    this.saleService = injector.get(SaleService);
    this.additionalPropertiesConfigurationService = injector.get(AdditionalPropertiesConfigurationService);
  }

  onDenominationClick(isCashPayment = true) {
    if (!this.additionalPropertiesConfigurationService.isDemoMode) {
      return;
    }

    this.vuCommunicationService.vuHttp.demoPayment().subscribe(
      result => {
        if (isCashPayment) {
          this.cashDemoPaymentComplete();
        } else {
          this.creditCardDemoPaymentComplete();
        }
      }
    );
  }

  cashDemoPaymentComplete() {
    const amountToPay = this.saleService.paymentSession.amountToPay.clone();
    this.saleService.onMoneyChanged(amountToPay);
  }

  creditCardDemoPaymentComplete() {
    let creditCardTerminalEvent = new CreditCardTerminalEvent(CreditCardTerminalEventType.CardInserted, 'Demo payment - Insert Card');
    this.vuCommunicationService.vuConnection.eventCreditCardTerminalEvent.emit(creditCardTerminalEvent);

    creditCardTerminalEvent = new CreditCardTerminalEvent(
      CreditCardTerminalEventType.FundsReservationIsCompleted, 'Demo payment - Funds Reservation Is Completed');
    this.vuCommunicationService.vuConnection.eventCreditCardTerminalEvent.emit(creditCardTerminalEvent);

    creditCardTerminalEvent = new CreditCardTerminalEvent(CreditCardTerminalEventType.PaymentCompleted, 'Demo payment - Payment Completed');
    this.vuCommunicationService.vuConnection.eventCreditCardTerminalEvent.emit(creditCardTerminalEvent);

    creditCardTerminalEvent = new CreditCardTerminalEvent(CreditCardTerminalEventType.CardRemoved, 'Demo payment - Card Removed');
    this.vuCommunicationService.vuConnection.eventCreditCardTerminalEvent.emit(creditCardTerminalEvent);
  }
}
