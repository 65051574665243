import { DisplayItem } from '../display-item/display-item';
import { DisplayConfiguration } from '../configuration/display-configuration';

export class GridTreeState {
  items: DisplayItem[];
  stack: DisplayItem[][] = [];
  rootStack: DisplayItem[] = [];
  parentItem: DisplayItem;
  displayConfiguration: DisplayConfiguration;

  initialize(displayConfiguration: DisplayConfiguration) {
    if (!displayConfiguration) {
      return;
    }

    this.displayConfiguration = displayConfiguration;
    this.positionToMainRoot();
  }

  nextStep(displayItem: DisplayItem): DisplayItem[] {
    if (!displayItem) {
      return this.items;
    }

    if (displayItem.navigationItem && displayItem.canUseNavigationItem) {
      return this.nextStep(displayItem.navigationItem);
    }

    if (!displayItem.isDynamic) {
      const displayItemInRoot = this.getDisplayItemFromRoot(displayItem.id);
      if (displayItemInRoot) {
        if (displayItemInRoot.navigateToRootPage) {
          this.positionToMainRoot();
          return this.items;
        }

        while (this.rootStack.includes(displayItemInRoot)) {
          this.stack.pop();
          this.rootStack.pop();
        }

        this.parentItem = displayItemInRoot;
        this.items = displayItemInRoot.children || [];

        return this.items;
      }
    }

    if (displayItem.navigateToRootPage) {
      this.positionToMainRoot();
      return;
    }

    this.stack.push(this.items);
    this.rootStack.push(this.parentItem);
    this.parentItem = displayItem;
    this.items = displayItem.children || [];

    return this.items;
  }

  getDisplayItemFromRoot(displayItemId: number): DisplayItem {
    if (!this.rootStack) {
      return null;
    }

    for (const displayItem of this.rootStack) {
      if (displayItem && displayItem.id === displayItemId) {
        return displayItem;
      }
    }

    return null;
  }

  positionToMainRoot() {
    if (!this.displayConfiguration) {
      return [];
    }
    let displayItems = this.displayConfiguration.items;

    this.stack = []
    this.rootStack = [];
    this.parentItem = null;

    if (displayItems && displayItems.length == 1 && displayItems[0] && displayItems[0].children) {
      this.parentItem = displayItems[0];
      displayItems = this.parentItem.children;
    }

    this.items = displayItems || [];
  }

  backStep(): DisplayItem[] {
    this.items = this.stack.pop();
    this.parentItem = this.rootStack.pop();
    return this.items;
  }

  get isStackEmpty(): boolean {
    return this.stack.length === 0;
  }
}
