import { Component, Input } from '@angular/core';
import { DisplayItem } from '../../../../lib/display/display-item/display-item';
import { KeypadGridWorkflowService } from '../../../../services/display/grid/text-input/keypad/keypad-grid-workflow.service';

@Component({
  selector: 'app-display-item-keypad',
  templateUrl: './keypad.component.html',
  styleUrls: ['./keypad.component.sass']
})
export class DisplayItemKeypadComponent {
  @Input() displayItem: DisplayItem;

  constructor(
    private keypadGridWorkflowService: KeypadGridWorkflowService,
  ) {

  }

  keyPressed(keyEvent: { char: string, action: any }) {
    this.keypadGridWorkflowService.keypadOutputEvent(keyEvent);
  }

  get isVirtualKeyboardVisible(): boolean {
    return true;
  }
}
