import { Component, OnInit, Input, EventEmitter, Output,  } from '@angular/core';
import { DisplayItem } from '../../../../lib/display/display-item/display-item';

@Component({
  selector: 'app-display-item-workflow',
  templateUrl: './list-workflow.component.html',
  styleUrls: ['./list-workflow.component.css']
})
export class DisplayItemListWorkflowComponent implements OnInit {
  @Input()
  displayItem: DisplayItem;

  @Output()
  onClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(
  ) {

  }

  ngOnInit() {
  }

}
