import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { KeyboardType } from '../on-screen-keyboard/on-screen-keyboard.component';

@Component({
  selector: 'app-virtual-keypad',
  templateUrl: './virtual-keypad.component.html',
  styleUrls: ['./virtual-keypad.component.sass']
})
export class VirtualKeypadComponent implements OnInit {

  @Input() layout: any[];
  @Output() keyPressed: EventEmitter<{char: string, action: any}> = new EventEmitter<{char: string, action: any}>();
  @Input() keyboardType: KeyboardType = KeyboardType.All;

  constructor() { }

  ngOnInit() {
  }

  keyPress(key: any) {
    const char = key.value;
    const action = key.action;
    this.keyPressed.emit({char, action});
  }

}
