import { WorkflowStepType, Order } from '../../lib/lib';
import { MachineBaseService } from './machine-base.service';
import {
  Money,
  PaymentSession,
  OrderSaveResult,
  WorkflowStepState,
  TeaserType,
  PrintTaskType,
  PrintTask,
  PrintTaskResult,
  MachineInactivitySettings,
  PaymentMethod,
} from '../../lib/lib';
import { SaleService } from '../sale.service';
import { MessageType, Message } from '../message.service';
import { CreditCardTerminalEvent } from '../../lib/credit-card/credit-card-terminal-event';
import { CreditCardTerminalEventType } from '../../lib/credit-card/credit-card-terminal-event-type';
import { PostSaleTypeEnum } from '../../lib/post-sale-step';


export class MachinePostSaleService extends MachineBaseService {
  private saleService: SaleService;
  private paymentSession: PaymentSession;
  private isCreditCardPayment = false;
  isBackToHomeAfterSale: boolean;
  disableNavigation = false;

  private createdTicketBarcode = '';
  private postSaleStepIndex = 0;
  private postSaleSteps = [
    PostSaleTypeEnum.PrintCreditCardReceipt,
    PostSaleTypeEnum.WaitingForMoney,
    PostSaleTypeEnum.SaveOrder,
    PostSaleTypeEnum.CheckExitRole,
    PostSaleTypeEnum.PrintTickets,
    PostSaleTypeEnum.PrintReceipt,
    PostSaleTypeEnum.OpenGate,
  ];

  init(): void {
    super.init();
    this.saleService = this.injector.get(SaleService);
    this.saleService.eventMoneyChanged.subscribe((x: Money) => this.onMoneyChanged(x));
    this.vuConnection.eventReturnChangeFinished.subscribe(() => this.onReturnChangeFinished());
    this.vuConnection.eventOrderSaveResultReceived.subscribe((x: OrderSaveResult) => this.onOrderSaveResult(x));
    this.vuConnection.eventPrintTaskResultReceived.subscribe((x: PrintTaskResult) => this.onPrintTaskResultReceived(x));
    this.vuConnection.eventPrintTicketRemoved.subscribe((x: PrintTaskResult) => this.onPrintTicketRemoved(x));
    this.vuConnection.eventCreditCardTerminalEvent.subscribe(
      (x: CreditCardTerminalEvent) => this.onEventCreditCardTerminalEvent(x)
    );
  }

  get machineName(): string {
    return 'Post Sale Machine';
  }

  protected getTransitions(): any[] {
    return super.getTransitions(
      { name: 'toFlowBegin', from: ['off'], to: 'flowBegin' },
      { name: 'toPrintCreditCardReceipt', from: ['flowBegin'], to: 'printCreditCardReceipt' },
      { name: 'toWaitingForMoney', from: ['flowBegin'], to: 'waitingForMoney' },
      { name: 'toWaitingForMoneyTimeout', from: ['waitingForMoney'], to: 'waitingForMoneyTimeout' },
      { name: 'toCancelled', from: ['waitingForMoneyTimeout'], to: 'cancelled' },
      {
        name: 'toReturningAmountPaid', from: [
          'cancelled',
          'returningAmountChange',
          'saveOrder',
          'printTickets'
        ], to: 'returningAmountPaid'
      },
      { name: 'toRevertingCardTerminalTransaction', from: ['returningAmountPaid'], to: 'revertingCardTerminalTransaction' },
      { name: 'toCheckForChange', from: ['waitingForMoneyTimeout'], to: 'checkForChange' },
      { name: 'toReturningAmountChange', from: ['checkForChange'], to: 'returningAmountChange' },
      { name: 'toSaveOrder', from: ['checkForChange', 'returningAmountChange', 'printCreditCardReceipt', 'flowBegin'], to: 'saveOrder' },
      { name: 'toPrintTickets', from: ['saveOrder'], to: 'printTickets' },
      { name: 'toPrintReceipt', from: ['saveOrder', 'printTickets'], to: 'printReceipt' },
      { name: 'toGateReadBarcode', from: ['saveOrder', 'printTickets', 'printReceipt'], to: 'gateReadBarcode' },
      { name: 'toFlowEnd', from: ['*'], to: 'flowEnd' },
    );
  }

  protected getMethods(): any {
    const scope = this;
    return super.getMethods({
      onToOff(event: any, isHardReset: false) {
        scope.paymentSession = null;
        scope.disableNavigation = false;
        scope.dispatcherService.isBackButtonEnabled = true;
      },
      onToFlowBegin() {
        scope.resetStepsData();
        if (!scope.disableNavigation) {
          scope.isBackToHomeAfterSale = false;
          scope.dispatcherService.isBackButtonEnabled = false;
        }
        scope.paymentSession = scope.saleService.paymentSession;
        scope.isCreditCardPayment = scope.saleService.order.paymentMethod === PaymentMethod.PaymentCard;
        const workflowName = scope.paymentSession.amountRemainingToPay.value > 0 ? 'Cancel' : 'Completing Process';
        scope.dispatcherService.workflowReset(workflowName, WorkflowStepType.None);
        scope.dispatcherService.teaserReset();
        scope.log.info(`MachinePostSaleService. onToFlowBegin. isCreditCardPayment: ${scope.isCreditCardPayment} ${scope.paymentSession}`);

        scope.doAsync(() => {
          if (!scope.disableNavigation) {
            scope.router.navigateByUrl('/workflow');
          }
          scope.nextStep();
        }, 'onToFlowBegin');
      },
      onToPrintCreditCardReceipt() {
        scope.dispatcherService.workflowAddStep(WorkflowStepType.CreditCardReceiptPrinting);
        const creditCardReceipt = scope.paymentSession.cardTerminalReceipt;
        const task = new PrintTask(scope.saleService.order.id, PrintTaskType.CreditCardReceipt, creditCardReceipt);
        scope.log.info(`onToPrintCreditCardReceipt. ${task}`);
        scope.vuHttp.print(task);
      },
      onToWaitingForMoney() {
        scope.dispatcherService.workflowAddStep(WorkflowStepType.Wait);
        const toWaitingForMoneyTimeout = () =>
          scope.doAsync(() => scope.machine.toWaitingForMoneyTimeout(), 'onToWaitingForMoney');

        let waitingForMoneyTimeout = 0;
        if (scope.paymentSession.amountPaidIn.isPositive) {
          waitingForMoneyTimeout = 1500;
        }

        setTimeout(() => {
          if (scope.paymentSession.isCancelled) {
            scope.closeRemoteTransaction(false, 'Canceled.', toWaitingForMoneyTimeout);
          } else {
            toWaitingForMoneyTimeout();
          }
        }, waitingForMoneyTimeout);
      },
      onToWaitingForMoneyTimeout() {
        scope.dispatcherService.workflowLastStepStateSet();
        if (scope.paymentSession.isCancelled) {
          scope.doAsync(() => scope.machine.toCancelled(), 'onToWaitingForMoneyTimeout. toCancelled');
        } else {
          scope.doAsync(() => scope.machine.toCheckForChange(), 'onToWaitingForMoneyTimeout. toCheckForChange');
        }
      },
      onToCancelled() {
        scope.dispatcherService.workflowDeleteLastStep();
        if (scope.paymentSession.amountPaidIn.isZero) {
          scope.dispatcherService.isBackButtonEnabled = true;
          // Direct to previous screen
          scope.doAsync(() => scope.machine.toOff(), 'onToCancelled. amountPaid.isZero');
        } else {
          scope.doAsync(() => scope.machine.toReturningAmountPaid(),
            'onToCancelled. toReturningAmountPaid');
        }
      },
      onToReturningAmountPaid() {
        if (scope.isCreditCardPayment) {
          scope.doAsync(
            () => scope.machine.toRevertingCardTerminalTransaction(),
            'onToReturningAmountPaid. isCreditCardPayment'); // TODO: Refactore to return the money to the card.
          return;
        }

        scope.do(() => {
          scope.paymentSession.amountTempToPayOut = scope.paymentSession.amountPaidIn
            .distract(scope.paymentSession.amountChangePaidOut);
          scope.dispatcherService.workflowAddStep(WorkflowStepType.PaymentReturn,
            ...scope.getPayoutArgs());

          const amountPaidOut = scope.paymentSession.amountPaidOut;
          const isCancelled = scope.paymentSession.isCancelled;

          if (!this.disableNavigation) {
            scope.isBackToHomeAfterSale = true;
          }

          scope.vuHttp.cashDevicesPayOut(true);

          if (isCancelled && !amountPaidOut.isZero) {
            scope.doAsync(() => {
              scope.onMoneyChanged(amountPaidOut.negate());
            }, 'onToReturningAmountPaid. isCancelled && !amountPaidOut.isZero');
          }

          if (isCancelled) {
            // Initiate payout
            scope.closeRemoteTransaction(false, 'Canceled with incomming payment.');
          }
        }, 'onToReturningAmountPaid');
      },
      onToRevertingCardTerminalTransaction() {
        scope.dispatcherService.workflowAddStep(WorkflowStepType.RevertingCardTerminalTransaction);
        scope.vuHttp.revertCardTerminalTransaction().catch(reason => {
          scope.onEventCreditCardTerminalEvent(new CreditCardTerminalEvent(CreditCardTerminalEventType.RevertTransactionFailed, ''));
        });
        scope.vuHttp.cancelOrder(scope.saleService.order);
      },
      onToCheckForChange() {
        scope.dispatcherService.workflowDeleteLastStep();
        if (scope.paymentSession.isFloatingAmount) {
          scope.dispatcherService.workflowAddStep(WorkflowStepType.AmountPaid, scope.paymentSession.amountPaidIn.toStringCurrencySign());
        } else {
          scope.dispatcherService.workflowAddStep(WorkflowStepType.AmountReached,
            scope.paymentSession.amountToPay.toStringCurrencySign(),
            scope.paymentSession.amountPaidIn.toStringCurrencySign());
        }

        scope.dispatcherService.workflowLastStepStateSet();
        if (scope.paymentSession.amountChange.isPositive && !scope.isCreditCardPayment) {
          scope.doAsync(() => scope.machine.toReturningAmountChange(),
            'onToCheckForChange. toReturningAmountChange');
        } else {
          scope.doAsync(() => scope.nextStep(),
            'onToCheckForChange. toSaveOrder');
        }
      },
      onToReturningAmountChange() {
        scope.do(() => {
          scope.paymentSession.amountTempToPayOut = scope.paymentSession.amountChange;
          scope.dispatcherService.workflowAddStep(WorkflowStepType.ChangeReturn,
            ...scope.getPayoutArgs());
          scope.vuHttp.cashDevicesPayOut(true);
          scope.doAsync(() => scope.checkAmountPaidOut(), 'onToReturningAmountChange');
        }, 'onToReturningAmountPaid');
      },
      onToSaveOrder() {
        scope.dispatcherService.workflowAddStep(WorkflowStepType.SaveOrder);
        const order = scope.saleService.order;

        scope.vuHttp.saveOrder(order).catch(reason => {
          scope.onOrderSaveResult(new OrderSaveResult(0,
            scope.saleService.order.uid, false, reason));
        });
      },
      onToPrintTickets() {
        scope.dispatcherService.workflowAddStep(WorkflowStepType.TicketPrinting);
        scope.vuHttp.print(new PrintTask(scope.saleService.order.id, PrintTaskType.Ticket))
          .catch((x) => {
            scope.onPrintTicketsResultReceived(false);
          });
      },
      onToPrintReceipt() {
        scope.dispatcherService.workflowAddStep(WorkflowStepType.ReceiptPrinting);
        scope.vuHttp.print(new PrintTask(scope.saleService.order.id, PrintTaskType.Receipt));
      },
      onToGateReadBarcode(event: any, barcode: string) {
        scope.log.info(`gateReadBarcode. barcode: ${barcode}}`);
        scope.dispatcherService.gateOpen(barcode);
        scope.dispatcherService.onGateOpenComplite((message) => {
          scope.machine.toFlowEnd();
        });
      },
      onToFlowEnd() {
        if (!scope.disableNavigation) {
          scope.dispatcherService.isBackButtonEnabled = true;
          scope.isBackToHomeAfterSale = true;
        }
        scope.closeRemoteTransaction(true, 'Order saved. Role exit.');
        if (scope.disableNavigation) {
          scope.doAsync(() => scope.machine.toOff(), 'onToFlowEnd. toOff');
        }
      },
    });
  }

  protected getMessages(): MessageType[] {
    return super.getMessages(
      MessageType.ButtonBackClicked,
    );
  }

  protected onMessage(message: Message): boolean {
    if (super.onMessage(message)) {
      return true;
    }

    const state = this.state;
    switch (message.messageType) {
      case MessageType.ButtonBackClicked:
        if (state === 'flowEnd') {
          this.machine.toOff();
        }
        break;
      default:
        break;
    }
    return false;
  }

  machineStart() {
    if (this.isOff) {
      this.machine.toFlowBegin();
    }
  }

  private onMoneyChanged(money: Money) {
    switch (this.state) {
      case 'returningAmountPaid':
        this.dispatcherService.workflowLastStepUpdate(... this.getPayoutArgs());
        if (this.paymentSession.isAmountPaidPaidOut) {
          this.dispatcherService.teaserAddItem(TeaserType.ReturnMoney);
          this.onRequestedPayoutComplete(() => this.machine.toFlowEnd());
        }
        break;
      case 'returningAmountChange':
        this.dispatcherService.workflowLastStepUpdate(... this.getPayoutArgs());
        const m = `totalPaidOut: ${this.paymentSession.amountPaidOut}. amountChange: ${this.paymentSession.amountChange}`;
        this.log.info(`MachinePostSaleService. ${m}`);
        this.checkAmountPaidOut();
        break;
      default:
        break;
    }
  }

  private onReturnChangeFinished() {
    const scope = this;
    setTimeout(() => {
      scope.onReturnChangeFinishedWithDelay();
    }, 1000); // to avoid the racing with the last eventMoneyChanged event
  }

  private onReturnChangeFinishedWithDelay() {
    if (this.state !== 'returningAmountChange') {
      this.log.info(`onReturnChangeFinished. Skip becase ${this.state} != 'returningAmountChange'`);
      return;
    }

    if (this.paymentSession.isAmountChangePaidOut) {
      this.log.info(`onReturnChangeFinished. Skip becase isAmountChangePaidOut`);
      return;
    }

    this.log.warning(`onReturnChangeFinished. Change not paid out!`);
    this.cancelSessionAndReturnMoney();
  }

  private checkAmountPaidOut() {
    if (this.paymentSession.isAmountChangePaidOut) {
      this.paymentSession.amountChangePaidOut = this.paymentSession.amountPaidOut;
      this.paymentSession.resetAmountTempPayout();

      this.dispatcherService.teaserAddItem(TeaserType.TakeChange);
      this.onRequestedPayoutComplete(() => this.nextStep());
    }
  }

  private onRequestedPayoutComplete(machineSwitch: () => void) {
    this.dispatcherService.workflowLastStepStateSet();
    this.vuHttp.cashDevicesPayOut(false);
    machineSwitch();
  }

  private onOrderSaveResult(result: OrderSaveResult) {
    if (this.state !== 'saveOrder') {
      return;
    }
    result = OrderSaveResult.fromOther(result);
    this.saleService.order.id = result.orderId;
    const m = `MachinePostSaleService. onOrderSaveResult: ${result}`;
    if (result.isSuccess) {
      this.log.info(m);
    } else {
      this.log.error(m);
    }
    const order = this.saleService.order;
    if (result.isSuccess
      && result.orderUid === order.uid
      && result.orderId != null) {
      this.dispatcherService.workflowLastStepStateSet();

      if (this.isReadBarcodeAtGateAfterPayment) {
        const barcode = result.barcodesOfCreatedTickets != null &&
          result.barcodesOfCreatedTickets.length !== 0 ? result.barcodesOfCreatedTickets[0] : null;
        if (barcode == null) {
          this.log.warning('onOrderSaveResult. Order save result has no barcode.');
        }
        this.createdTicketBarcode = barcode;
      }
      this.nextStep();
    } else {
      this.dispatcherService.workflowLastStepStateSet(WorkflowStepState.CompleteFailure);
      this.paymentSession.isCancelled = true;
      this.machine.toReturningAmountPaid();
    }
  }

  private resetStepsData() {
    this.postSaleStepIndex = 0;
    this.createdTicketBarcode = '';
  }

  private nextStep() {
    const order = this.saleService.order;
    if (!order) {
      this.machine.toFlowEnd();
      return;
    }

    while (this.postSaleSteps.length > this.postSaleStepIndex) {
      const nextStep = this.postSaleSteps[this.postSaleStepIndex];
      this.postSaleStepIndex++;
      switch (nextStep) {
        case PostSaleTypeEnum.PrintCreditCardReceipt:
          if (this.isCreditCardPayment && order.isCardTerminalReceipt) {
            this.machine.toPrintCreditCardReceipt();
            return;
          }
          break;
        case PostSaleTypeEnum.WaitingForMoney:
          if (!this.isCreditCardPayment) {
            this.machine.toWaitingForMoney();
            return;
          }
          break;
        case PostSaleTypeEnum.SaveOrder:
          this.machine.toSaveOrder();
          return;
        case PostSaleTypeEnum.CheckExitRole:
          if (this.isExitRole) {
            this.machine.toFlowEnd();
            return;
          }
          break;
        case PostSaleTypeEnum.PrintTickets:
          if (order.hasTicketsToPrint) {
            this.machine.toPrintTickets();
            return;
          }
          break;
        case PostSaleTypeEnum.PrintReceipt:
          if (order.isReceipt) {
            this.machine.toPrintReceipt();
            return;
          }
          break;
        case PostSaleTypeEnum.OpenGate:
          if (this.isReadBarcodeAtGateAfterPayment) {
            this.machine.toGateReadBarcode(this.createdTicketBarcode);
            return;
          }
          break;
      }
    }
    this.machine.toFlowEnd();
  }

  private onPrintTaskResultReceived(result: PrintTaskResult) {
    const printTaskType = result.printTask.printTaskType;
    if (printTaskType === PrintTaskType.Ticket) {
      this.onPrintTicketsResultReceived(result.isSuccess);
    } else if (printTaskType === PrintTaskType.Receipt) {
      this.onPrintReceiptResultReceived(result.isSuccess);
    } else if (printTaskType === PrintTaskType.CreditCardReceipt) {
      this.onPrintCreditCardReceiptResultReceived(result.isSuccess);
    } else {
      this.log.error(`onPrintTaskResultReceived. Unsupported PrintTaskType: ${printTaskType}`);
    }
  }

  private onPrintTicketRemoved(result: PrintTaskResult) {
    if (this.isInState('flowEnd') && !this.saleService.remoteTransaction.isOpened
    ) {
      this.machine.toOff();
    }
  }

  get isReadBarcodeAtGateAfterPayment(): boolean {
    return this.additionalPropertiesConfigurationService.isReadBarcodeAtGateAfterPayment;
  }

  private onPrintTicketsResultReceived(result: boolean) {
    if (this.state !== 'printTickets') {
      return;
    }
    this.log.info(`MachinePostSaleService. onPrintTicketsResultReceived. result: ${result}`);
    if (result) {
      const type = this.saleService.order.orderLines.length > 1 ? TeaserType.TakeTickets : TeaserType.TakeTicket;
      this.dispatcherService.teaserAddItem(type);
      this.dispatcherService.workflowLastStepStateSet();
      this.nextStep();
    } else {
      this.dispatcherService.workflowLastStepStateSet(WorkflowStepState.CompleteFailure);
      this.paymentSession.isCancelled = true;
      if (!this.isCreditCardPayment) {
        this.vuHttp.cancelOrder(this.saleService.order);
      }
      this.machine.toReturningAmountPaid();
    }
  }

  private onPrintReceiptResultReceived(result: boolean) {
    if (this.state !== 'printReceipt') {
      return;
    }
    this.log.info(`MachinePostSaleService. onPrintReceiptResultReceived. result: ${result}`);
    this.dispatcherService.teaserAddItem(TeaserType.TakeReceipt);
    const step = result ? WorkflowStepState.CompleteSuccess : WorkflowStepState.CompleteFailure;
    this.dispatcherService.workflowLastStepStateSet(step);
    this.nextStep();
  }

  private onPrintCreditCardReceiptResultReceived(result: boolean) {
    if (this.state !== 'printCreditCardReceipt') {
      return;
    }
    this.log.info(`MachinePostSaleService. onPrintCreditCardReceiptResultReceived. result: ${result}`);
    const step = result ? WorkflowStepState.CompleteSuccess : WorkflowStepState.CompleteFailure;
    this.dispatcherService.workflowLastStepStateSet(step);
    this.nextStep();
  }

  protected get timeoutTrackingStates(): string[] {
    if (this.isReadBarcodeAtGateAfterPayment) {
      return this.getsAllStatesExceptFor('off', 'gateWaitingForEnter');
    }
    return this.getsAllStatesExceptFor('off');
  }

  private getPayoutArgs(): string[] {
    try {
      const result = [
        this.paymentSession.amountTempToPayOut.toStringCurrencySign(),
        this.paymentSession.amountTempPaidOut.toStringCurrencySign()
      ];
      return result;
    } catch (e) {
      this.log.error(this.paymentSession);
    }
    return ['?', '?'];
  }

  closeRemoteTransaction(isToCommit: boolean, context: string, revertPaymentTransactionCallback: any = null) {
    this.saleService.remoteTransaction.closeRemoteTransaction(
      isToCommit,
      context,
      revertPaymentTransactionCallback == null ? null : revertPaymentTransactionCallback.bind(this)
    );
  }

  private onEventCreditCardTerminalEvent(x: CreditCardTerminalEvent) {
    if (this.state !== 'revertingCardTerminalTransaction') {
      this.log.info(`onEventCreditCardTerminalEvent. The machine is not in 'revertingCardTerminalTransaction' state. Ignore the event.`);
      return;
    }

    switch (x.eventType) {
      case CreditCardTerminalEventType.RevertTransactionCompleted:
        this.dispatcherService.workflowLastStepStateSet();
        this.machine.toFlowEnd();
        break;
      case CreditCardTerminalEventType.RevertTransactionFailed:
        this.dispatcherService.workflowLastStepStateSet(WorkflowStepState.CompleteFailure);
        this.machine.toFlowEnd();
        break;
      default:
        this.log.error(`This CreditCardTerminalEvent not supported: ${x}`);
        break;
    }
  }

  protected onMachineTimeoutModalCancel(machineName: string) {
    if (machineName === this.machineName
      && this.isInState('toWaitingForMoney', 'returningAmountChange', 'printTickets', 'saveOrder')) {
      this.cancelSessionAndReturnMoney();
      return;
    }
    this.closeRemoteTransaction(false, 'timeout');
    super.onMachineTimeoutModalCancel(machineName);
  }

  private cancelSessionAndReturnMoney() {
    this.paymentSession.isCancelled = true;
    this.dispatcherService.workflowLastStepStateSet(WorkflowStepState.CompleteFailure);
    this.machine.toReturningAmountPaid();
  }

  protected getMachineInactivitySettings(state: string): MachineInactivitySettings {
    switch (state) {
      case 'flowEnd':
        return new MachineInactivitySettings(10000, true);
      default:
        return super.getMachineInactivitySettings(state);
    }
  }
}
