import { Injectable } from '@angular/core';
import { PaymentBaseGridWorkflowService } from '../payment-base-workflow.service';
import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';
import { DisplayItem } from '../../../../../lib/display/display-item/display-item';

@Injectable()
export class PaymentConfirmationGridWorkflowService extends PaymentBaseGridWorkflowService {

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.PaymentConfirmation;
  }

  show(displayItem: DisplayItem, context: any) {
    super.show(displayItem, context);

    this.dispatcherService.toDisplayModePayment(false);
  }

  get hardResetProccessDisplayItem(): DisplayItem {
    if (this.saleService.paymentSession.isFloatingAmount && this.isPaymentConfirmationAvalible) {
      return this.findDisplayItemByUniqueName('payment_confirmation');
    }
    return null;
  }

  getNavigationItem(info: any): DisplayItem {
    const navigationItem = info && this.displayItem ? this.displayItem.navigationItem : this.displayItem.failureItem;
    return navigationItem;
  }
}
