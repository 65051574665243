import { Component, OnInit, Input, EventEmitter, Output,  } from '@angular/core';
import { DisplayItem } from '../../../../lib/display/display-item/display-item';
import { PinGridWorkflowService } from '../../../../services/display/grid/pin/pin/pin-grid-workflow.service';
import { LanguageService } from '../../../../services/language.service';
import { DisplayGridWorkflowService } from '../../../../services/display/grid/display-grid-workflow.service';

@Component({
  selector: 'app-display-item-pinpad',
  templateUrl: './pinpad.component.html',
  styleUrls: ['./pinpad.component.css']
})
export class DisplayItemPinpadComponent implements OnInit {
  @Input()
  displayItem: DisplayItem;

  @Output()
  onClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private pinGridWorkflowService: PinGridWorkflowService,
    private languageService: LanguageService,
    private displayGridWorkflowService: DisplayGridWorkflowService,
  ) {
  }

  ngOnInit() {
  }

  get language(): string {
    return this.languageService.odooLanguageName;
  }

  onItemClick() {
    if (this.displayItem != null && this.displayItem != undefined) {
      this.onClick.emit(this.displayItem);
    }
  }

  setName(item: DisplayItem) {
    return item.name + ` ${item.sizeHorizontal - item.posX}x` + `${item.sizeVertical - item.posY}`;
  }

  get isVirtualKeyboardVisible(): boolean {
    return true;
  }

  onEnteredText(text: string) {
    this.pinGridWorkflowService.pinInputComplete(text);
  }

  onTextChanged() {

  }

  renderHtmlTemplate(displayItem: DisplayItem): string {
    return this.displayGridWorkflowService.getformattedHtmlTemplate(displayItem, this.language);
  }
}
