import { DtoVuConfiguration } from '../../lib/configuration';
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Configuration, VuRole } from '../../lib/lib';
import { Router } from '@angular/router';
import { DispatcherService } from '../dispatcher.service';
import { LanguageService } from '../language.service';
import { IVuHttp } from '../vu/http/vu-http.interface';
import { LoggingService } from '../logging/logging.service';
import { IVuConnection } from '../vu/connection/vu-connection.interfaces';
import { VuCommunicationService } from '../vu/vu-communication.service';
import { TicketInfoTwoSidedComponent } from '../../components/ticket/ticket-info-two-sided/ticket-info-two-sided.component';
import { ScreenSaverService } from '../screen-saver.service';
import { BarcodeInputMethod } from '../../lib/barcode-reader/barcode-input-method';
import { OneLineArticleSaleMode } from '../../lib/one-line-article-sale-mode';
import { Asset } from '../../lib/assets/asset';
import { PrintReceiptEnum } from '../../lib/printer/print-receipt-enum';


@Injectable()
export class ConfigurationService {
  private dispatcherService: DispatcherService;
  private languageService: LanguageService;
  private vuHttp: IVuHttp;
  private log: LoggingService;
  private vuConnection: IVuConnection;
  private vuCommunicationService: VuCommunicationService;
  private router: Router;
  private screenSaverService: ScreenSaverService;
  configuration: Configuration = new Configuration();

  constructor(
    private http: HttpClient,
    private injector: Injector,
  ) {
  }

  load(): Promise<any> {
    return this.http.get('./config.json')
      .map((res) => res)
      .toPromise()
      .then(x => {
        this.configuration = Configuration.fromOther(x);
        this.router = this.injector.get(Router);
        this.updateRoute();
        this.languageService = this.injector.get(LanguageService);
        this.dispatcherService = this.injector.get(DispatcherService);
        this.dispatcherService.configurationUpdate(this.configuration);
        this.log = this.injector.get(LoggingService);
        this.dispatcherService.onConfigurationChangedSubscribe((y: Configuration) => {
          this.configuration = y;
          this.log.info(`ConfigurationService. Configuration:  ${y}`);
        });
        this.vuCommunicationService = this.injector.get(VuCommunicationService);
        this.vuHttp = this.vuCommunicationService.vuHttp;
        this.getConfiguration();
        this.updateScreenSaverConfiguration();
        this.vuConnection = this.vuCommunicationService.vuConnection;
        this.vuConnection.eventConnectionChanged.subscribe((isConnected: boolean) => this.onConnectionChanged(isConnected));
        this.vuConnection.eventVuConfigurationChanged.subscribe(() => this.onVuConfigurationChanged());
        this.screenSaverService = this.injector.get(ScreenSaverService);
      });
  }

  private onConnectionChanged(isConnected: boolean) {
    if (isConnected) {
      this.getConfiguration();
      this.updateScreenSaverConfiguration();
    }
  }

  private onVuConfigurationChanged() {
    this.getConfiguration();
    this.updateScreenSaverConfiguration();
  }

  private getConfiguration() {
    this.vuHttp.getVuConfiguration().then((x) => {
      this.updateConfiguration(x);
    });
  }

  private updateConfiguration(x: DtoVuConfiguration) {
    this.configuration.backgroundId = x.backgroundId;
    this.configuration.showBasketButtonOnHeader = x.showBasketButtonOnHeader;
    this.configuration.vuRole = x.vuRole;
    this.configuration.locale = x.locale;
    this.configuration.showArticlesOnMainPage = x.showArticlesOnMainPage;
    this.configuration.printCardTerminalReceipt = PrintReceiptEnum[x.printCardTerminalReceipt];
    this.configuration.printOrderReceipt = PrintReceiptEnum[x.printOrderReceipt];
    this.configuration.oneLineArticleSaleMode = x.oneLineArticleSaleMode;
    this.configuration.barcodeInputMethod = x.barcodeInputMethod;
    this.configuration.ticketPrinterType = x.ticketPrinterType;
    this.configuration.receiptPrinterType = x.receiptPrinterType;
    this.updateAssetsConfiguration(x);
    this.configuration.customCss = x.customCss;
    this.configuration.backgroundId = x.backgroundId;
    this.configuration.additionalProperties = x.additionalProperties;
    this.configuration.customLogoId = x.customLogoId;
    this.configuration.odooUrl = x.odooUrl;
    this.configuration.odooToken = x.odooToken;
    this.languageService.defaultLanguageId = x.locale;
    this.languageService.setSelectedLanguageById(x.locale);
    this.updateDisplayConfiguration(x);
    this.dispatcherService.configurationChangeDate(x.changeDate);
  }

  updateScreenSaverConfiguration() {
    this.vuHttp.getScreenSaverConfiguration().then((x) => {
      this.screenSaverService.configuration = x;
    });
  }

  private updateDisplayConfiguration(x: DtoVuConfiguration) {
    this.configuration.displayConfiguration = x.displayConfiguration;
    this.configuration.displayConfigurationId = x.displayConfigurationId;
  }

  private updateAssetsConfiguration(x: DtoVuConfiguration) {
    this.configuration.assets = x.assets ? x.assets.map(Asset.fromAny) : null;
  }

  private updateRoute() {
    const customRoutes = this.configuration.customRoutes;
    if (customRoutes == null || this.router.config == null) {
      return;
    }

    const routerConfig = this.router.config.map(x => Object.assign({}, x));
    for (const customRoute of customRoutes) {
      const component = this.getComponent(customRoute.component);
      if (component == null) {
        continue;
      }
      customRoute.component = component;

      const path = customRoute.path;
      if (path == null) {
        continue;
      }
      const items = routerConfig.filter(x => x.path === path);
      const item = items.length > 0 ? items[0] : null;
      if (item == null) {
        continue;
      }
      const index = routerConfig.indexOf(item, 0);
      routerConfig[index] = customRoute;
    }
    this.router.resetConfig(routerConfig);
  }

  private getComponent(cls: string): any {
    if (cls == null) {
      return;
    }
    switch (cls) {
      case 'TicketInfoTwoSidedComponent':
        return TicketInfoTwoSidedComponent;
      default:
        return null;
    }
  }

  get isRoleExit(): boolean {
    return this.configuration.vuRole === VuRole.Exit;
  }

  get showLanguageButton(): boolean {
    return this.configuration.showLanguageButton;
  }

  get defaultShopTitle(): string {
    return this.configuration.defaultShopTitle;
  }

  get showArticlesOnMainPage(): boolean {
    return this.configuration.showArticlesOnMainPage;
  }

  get oneLineArticleSaleMode(): OneLineArticleSaleMode {
    return this.configuration.oneLineArticleSaleMode;
  }

  get barcodeInputMethod(): BarcodeInputMethod {
    return this.configuration.barcodeInputMethod;
  }
}
