import { Injectable, Injector } from '@angular/core';
import { Money, Ticket, OrderSaveResult, PrintTaskResult } from '../../../lib/lib';
import { VuConnectionBase } from './vu-connection-base.service';
import { TurnstileSimulatorService } from '../../turnstile/turnstile-simulator.service';
import { CIOBoardEvent } from '../../../lib/cioboard/coiboard-event';

@Injectable()
export class SignalRSimulatorService extends VuConnectionBase {

  constructor(
    protected injector: Injector,
    private turnstileSimulatorService: TurnstileSimulatorService,
  ) {
    super(injector);
  }

  init() {
    this.isConneсted = true;
    super.init();
  }

  changeNavigationUrl(url: string) {
    this.navigationUrlChanged.emit(url);
  }

  changeConnection(connected: boolean) {
    this.isConneсted = connected;
    this.eventConnectionChanged.emit(connected);
  }

  writeLogMessages(messages: string[]): Promise<any> {

    let isSent = Math.floor(Math.random() * 10) === 0;

    // if (isSent) {
    //     messages.forEach(message => {
    //         console.log('Simlog: %c' + message, 'color:gray');
    //     });
    // }

    return new Promise<any>((resolve, reject) => {
      if (isSent) {
        resolve();
      } else {
        reject();
      }
    });
  }

  barcodeRead(barcode: string) {
    super.barcodeRead(barcode);
  };

  scanTicket(ticket: Ticket) {
    super.scanTicket(ticket);
  }

  moneyChanged(money: Money) {
    super.moneyChanged(money);
    this.eventMoneyChanged.emit(money);
  }

  returnAmountFinished() {
    super.returnAmountFinished();
  }

  returnChangeFinished() {
    super.returnChangeFinished();
  }

  orderSaveResultReceived(result: OrderSaveResult) {
    super.orderSaveResultReceived(result);
    this.eventOrderSaveResultReceived.emit(result);
  }

  printTaskResultReceived(result: PrintTaskResult) {
    super.printTaskResultReceived(result);
    this.eventPrintTaskResultReceived.emit(result);
  }

  printTicketRemoved(result: PrintTaskResult) {
    super.printTicketRemoved(result);
    this.eventPrintTicketRemoved.emit(result);
  }

  connectionChanged(isConnected: boolean) {
    super.connectionChanged(isConnected);
  }

  onTurnstileEnter() {
    this.turnstileSimulatorService.closeEnter();
    super.onTurnstileEnter();
  }

  onInvalidRfidCardInserted() {
    super.onInvalidRfidCardInserted();
  }

  onGateTransactionBegin(x: boolean) {
    super.onGateTransactionBegin(x);
  }

  onGateTransactionEnd(x: boolean) {
    super.onGateTransactionEnd(x);
  }

  onBnaValidationStarted() {
    super.onBnaValidationStarted();
  }

  onBnaValidationFinished() {
    super.onBnaValidationFinished();
  }

  onCIOBoardStateChanged(x: CIOBoardEvent) {
    super.onCIOBoardStateChanged(x);
  }
}
