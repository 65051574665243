import { Injectable } from '@angular/core';
import { DisplayItem } from '../../../../../lib/display/display-item/display-item';
import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';
import { BaseGridWorkflowService } from '../../base/base-grid-workflow.service';
import { Product, Money } from '../../../../../lib/lib';

@Injectable()
export class CashRefundGridWorkflowService extends BaseGridWorkflowService {

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.CashRefund;
  }

  cashChanged(amount: number = 0, product: Product = null): void {
    this.userActivity();
    if (this.displayItem == null) {
      return;
    }
    this.displayGridWorkflowService.updateContext({
      refundAmount: new Money(amount, 'EUR'),
      refundProduct: product
    });
    this.updateNextButton(amount);
  }

  updateNextButton(amount: number) {
    let isRefundAvailable = false;
    if (this.displayItem != null && amount > 0) {
      const odooContext = this.displayGridWorkflowService.getContextParameter('odooContext');
      let moneyReturnCreditContext = 0;
      if (odooContext && odooContext.externalContext && odooContext.externalContext.MoneyReturnCredit) {
        moneyReturnCreditContext = odooContext.externalContext.MoneyReturnCredit;
      }
      if (amount >= this.displayItem.minAmount && amount <= this.displayItem.maxAmount &&
        amount <= moneyReturnCreditContext && amount % 1 === 0) {
        isRefundAvailable = true;
      }
    }
    if (isRefundAvailable) {
      this.vuCommunicationService.vuHttp.canPayoutAmount(new Money(amount, 'EUR')).then(result => {
        isRefundAvailable = result;
        this.displayGridWorkflowService.changeIsDisabledState('credit_confirmation', !isRefundAvailable);
      });
    } else {
      this.displayGridWorkflowService.changeIsDisabledState('credit_confirmation', !isRefundAvailable);
    }
  }

  show(displayItem: DisplayItem, context: any) {
    super.show(displayItem, context);
    this.updateNextButton(0);
  }
}
