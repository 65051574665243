import { Subscription } from 'rxjs/Rx';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Money, CashType, GlobalSettings, Order, PaymentSession, NonOperationalState, CreditCard } from '../../../lib/lib';
import { LoggingService } from '../../../services/logging/logging.service';
import { ConfigurationService } from '../../../services/configuration/configuration.service';
import { DispatcherService } from '../../../services/dispatcher.service';
import { SaleService } from '../../../services/sale.service';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';
import { NonOperationalService } from '../../../services/non-operational.service';
import { DemoPaymentService } from '../../../services/payment/demo-payment.service';
import { CreditCardMessageStore } from '../../../lib/credit-card/credit-card-message-store';
import { AdditionalPropertiesConfigurationService } from '../../../services/configuration/additional-properties-configuration.service';
import { PrintReceiptEnum } from '../../../lib/printer/print-receipt-enum';
import { PrintReceiptTypeEnum } from '../../../lib/printer/print-receipt-type-enum';

@Component({
  moduleId: module.id,
  selector: 'payment-card',
  templateUrl: 'payment-card.component.html',
  styleUrls: ['payment-card.component.scss']
})
export class PaymentCardComponent implements OnInit, OnDestroy {
  order: Order;
  paymentSession: PaymentSession;
  acceptedCreditCards: CreditCard[] = [];
  canPayWithCreditCard = true;
  nonOperationalSubscription: Subscription;
  creditCardMessageStore: CreditCardMessageStore;
  isProduction = true;
  private isBackButtonVisible: boolean;
  public printReceiptTypeEnums = PrintReceiptTypeEnum;

  constructor(
    private log: LoggingService,
    public configurationService: ConfigurationService,
    private dispatcherService: DispatcherService,
    private saleService: SaleService,
    private vuCommunicationService: VuCommunicationService,
    private nonOperationalService: NonOperationalService,
    private demoPaymentService: DemoPaymentService,
    private additionalPropertiesConfigurationService: AdditionalPropertiesConfigurationService,
  ) {
    this.order = saleService.order;
    this.creditCardMessageStore = saleService.creditCardMessageStore;
    this.paymentSession = saleService.paymentSession;
    this.isProduction = configurationService.configuration.isProduction;
  }

  ngOnInit(): void {
    this.isBackButtonVisible = this.dispatcherService.isBackButtonVisible;
    this.dispatcherService.isBackButtonVisible = true;
    this.dispatcherService.pageHeaderSet('Payment by card');
    this.dispatcherService.backButtonTextSet('Cancel');
    this.nonOperationalSubscription = this.nonOperationalService
      .eventStateChanged
      .subscribe((x: NonOperationalState) => this.canPayWithCreditCard = !x.isNonOperational);
    this.vuCommunicationService.vuHttp.getAcceptedCreditCards().then(x => {
      if (x) {
        if (this.additionalPropertiesConfigurationService.isContactlessCardAccepted) {
          const contactless = new CreditCard('Contactless');
          const result = x;
          result.push(contactless);
          this.acceptedCreditCards = result;
        } else {
          this.acceptedCreditCards = x;
        }
      }
    });
    this.order.isCardTerminalReceipt = this.configurationService.configuration.printCardTerminalReceipt == PrintReceiptEnum.ForcePrint;
  }

  ngOnDestroy() {
    this.dispatcherService.isBackButtonVisible = this.isBackButtonVisible;
    this.dispatcherService.pageHeaderSet();
    this.dispatcherService.backButtonTextReset();
    this.nonOperationalSubscription.unsubscribe();
  }

  get currencyCode() {
    return GlobalSettings.getCurrencyCode();
  }

  get amountToPay(): string {
    return this.paymentSession ? this.paymentSession.amountToPay.toStringCurrencySign() : 'ERROR';
  }

  onCardClick(event: any) {
    this.demoPaymentService.onDenominationClick(false);
  }

  get cssClassMessage(): string {
    return this.creditCardMessageStore && this.creditCardMessageStore.higlightMessage ? 'message blinking' : 'message';
  }

  get machineState(): string {
    return this.dispatcherService.machineSaleCreditCardState;
  }

  get receiptSwitcherStyle(): string {
    return 'left-mode-receipt-switcher';
  }

  get cardTerminalReceiptSwitcherStyle(): string {
    if (!this.showReceiptButton) {
      return 'left-mode-receipt-switcher';
    }

    return 'center-receipt-switcher';
  }

  get showReceiptButton(): boolean {
    if (
      !this.configurationService ||
      !this.configurationService.configuration ||
      !this.configurationService.configuration.printOrderReceipt) {
      return false;
    }

    return this.configurationService.configuration.printOrderReceipt === PrintReceiptEnum.UserChoice;
  }

  get showCardTerminalReceiptButton(): boolean {
    if (
      !this.configurationService ||
      !this.configurationService.configuration ||
      !this.configurationService.configuration.printCardTerminalReceipt) {
      return false;
    }

    return this.configurationService.configuration.printCardTerminalReceipt === PrintReceiptEnum.UserChoice;
  }
}
