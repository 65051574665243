import { Injectable } from '@angular/core';

import { BaseGridWorkflowService } from '../base/base-grid-workflow.service';
import { DisplayItem } from '../../../../lib/display/display-item/display-item';

@Injectable()
export class BaseViewerGridWorkflowService extends BaseGridWorkflowService {

  show(displayItem: DisplayItem, context: any) {
    super.show(displayItem, context);
    this.enableNextButton(false);
  }

  enableNextButton(enabled: boolean) {
    this.displayGridWorkflowService.changeIsDisabledState('accept_button', !enabled);
  }

  loadComplete(loadError = false) {
    this.enableNextButton(true);
  }
}
