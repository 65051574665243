export enum DisplayItemTypeEnum {
  Static = 'static',
  Debug = 'debug',
  Category = 'category',
  Product = 'product',
  Print = 'print',
  Ticket = 'ticket',
  Pin = 'pin',
  FmcuAction = 'fmcu_action',
  StepsListWorkflow = 'steps_list_workflow',
  CashPayment = 'cash_payment',
  PaymentConfirmation = 'payment_confirmation',
  PaymentCancellation = 'payment_cancellation',
  CashRefund = 'cash_refund',
  CashRefundConfirmation = 'cash_refund_confirmation',
  RfidCard = 'rfid_card',
  ReceiptSwitcher = 'receipt_switcher',
  Pdf = 'pdf',
  Image = 'image',
  CIOBoardEvent = 'cioboard_event',
  ServerAction = 'server_action',
  Basket = 'basket',
  CardPayment = 'card_payment',
  Keypad = 'keypad',
  InputText = 'input_text',
  CreateOrder = 'create_order',
  Markdown = 'markdown',
}
