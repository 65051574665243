import { Input, Injector } from '@angular/core';
import { DisplayItem } from '../../../lib/display/display-item/display-item';
import { DisplayItemAssetsService } from '../../../services/display/display-item-assets.service';

export abstract class BaseGridViewerComponent {

  @Input()
  displayItem: DisplayItem;

  protected internalLoading: boolean;
  protected previousUrl = '';
  protected displayItemAssetsService: DisplayItemAssetsService;

  constructor(
    private injector: Injector,
  ) {
    this.displayItemAssetsService = this.injector.get(DisplayItemAssetsService);
  }

  get url(): string {
    const url = this.buildUrl();
    if (this.previousUrl !== url) {
      this.loadStart();
      this.previousUrl = url;
    }
    return url;
  }

  get loading(): boolean {
    return this.internalLoading;
  }

  loadStart() {
    this.internalLoading = true;
  }

  loadComplete() {
    this.internalLoading = false;
  }

  buildUrl(): string {
    return this.displayItemAssetsService.buildUrl(this.displayItem);
  }
}
