import { Injectable, Injector } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import {
  VisualItem,
  Money,
  AcceptedCash,
  Order,
  Product,
  VuState,
  PrintTask,
  DtoVuConfiguration,
  DtoTicketUse,
  TicketUse,
  CreditCard,
  Ticket
} from '../../../lib/lib';
import { IVuHttp, IVuHttpSimulator } from './vu-http.interface';
import { LoggingService } from '../../logging/logging.service';
import { ScreenSaverConfiguration } from '../../../lib/screen-saver-configuration';
import { Observable } from 'rxjs';
import { of } from 'rxjs/observable/of';
import { TouchTileColor } from '../../../lib/touch-tile/touch-tile-color';
import { TouchTileSwitch } from '../../../lib/touch-tile/touch-tile-switch';
import { RunningLightScenario } from '../../../lib/touch-tile/running-light-scenario';
import { CreditCardTerminalState } from '../../../lib/credit-card/credit-card-terminal-state';
import { DisplayConfiguration } from '../../../lib/display/configuration/display-configuration';
import { CIOBoardPinEvent } from '../../../lib/cioboard/cioboard-pin-event';

@Injectable()
export abstract class VuHttpBaseService implements IVuHttp, IVuHttpSimulator {
  protected _useProductionApi = true;
  protected _log: LoggingService;
  constructor(
    protected injector: Injector,
  ) {
    this.init();
  }

  protected abstract init(): void;

  abstract getProducts(): Promise<Product[]>;
  abstract getProductsByIds(productIds: number[]): Promise<Product[]>;
  abstract getProduct(productId: number): Promise<Product>;
  abstract getSubProduct(productId: number): Promise<Product>;
  abstract getVisualItems(type: string): Promise<VisualItem[]>;
  abstract getDisplayConfiguration(configurationId: number): Promise<DisplayConfiguration>
  abstract getAcceptedCash(amount: Money): Promise<AcceptedCash>;
  abstract getAcceptedCreditCards(): Promise<CreditCard[]>;
  abstract getVuState(): Promise<VuState>;
  abstract getVuConfiguration(): Promise<DtoVuConfiguration>;
  abstract getTicketUse(code: string): Promise<TicketUse[]>;
  abstract canPayoutAmount(amount: Money): Promise<boolean>;

  print(task: PrintTask): Promise<any> {
    const m = `VuHttpBaseService. print. ${task}`;
    this.log.info(m);
    return this.returnEmptyPromise();
  }

  printByTemplateTypeUniqueName(templateTypeUniqueName: string, language: string): Promise<any> {
    const m = `VuHttpBaseService. printTemplateTypeUniqueName. templateTypeUniqueName: ${templateTypeUniqueName}, language ${language}`;
    this.log.info(m);
    return this.returnEmptyPromise();
  }

  openFmcuApi(url: string, body: object): Promise<any> {
    const m = `VuHttpBaseService. openFmcuApi. url:${url}. body:${JSON.stringify(body)}`;
    this.log.info(m);
    return this.returnEmptyPromise();
  }

  beginCardPaymentTransaction(amount: Money) {
    this.log.info(`VuHttpBaseService. beginCardPaymentTransaction. amount:${amount}`);
  }

  beginPaymentTransaction(amount: Money) {
    this.log.info(`VuHttpBaseService. beginPaymentTransaction. amount:${amount}`);
  }

  commitPaymentTransaction(force: boolean) {
    this.log.info(`VuHttpBaseService. commitPaymentTransaction. force:${force}`);
  }

  appendTransactionInfo(info: string) {
    this.log.info(`VuHttpBaseService. appendTransactionInfo. ${info}`);
  }

  revertPaymentTransaction(): Promise<any> {
    this.log.info(`VuHttpBaseService. revertPaymentTransaction.`);
    return this.returnEmptyPromise();
  }

  revertCardTerminalTransaction(): Promise<any> {
    this.log.info(`VuHttpBaseService. revertCardTerminalTransaction.`);
    return this.returnEmptyPromise();
  }

  returnAmount(amount: Money): Promise<any> {
    this.log.info(`VuHttpBaseService. returnAmount: ${amount}`);
    return this.returnEmptyPromise();
  }

  barcodeLedEnable(value: boolean): void {
    this.log.info(`VuHttpBaseService. barcodeLedEnable: ${value}`);
  }

  rfidCardLedEnable(value: boolean): void {
    this.log.info(`VuHttpBaseService. rfidCardLedEnable: ${value}`);
  }

  saveOrder(order: Order): Promise<any> {
    this.log.info(`VuHttpBaseService. saveOrder: ${order}`);
    return this.returnEmptyPromise();
  }

  cancelOrder(order: Order): Promise<any> {
    this.log.info(`VuHttpBaseService. cancelOrder: ${order}`);
    return this.returnEmptyPromise();
  }

  cashDevicesPayIn(allowPayIn: boolean) {
    this.log.info(`VuHttpBaseService. cashDevicesPayInOnSet: ${allowPayIn}`);
  }

  cashDevicesPayOut(allowPayOut: boolean) {
    this.log.info(`VuHttpBaseService. cashDevicesPayOutOnSet: ${allowPayOut}`);
  }

  scanTicket(barcode: string) {
    this.log.info(`VuHttpBaseService. scanTicket: ${barcode}`);
  }

  getTicketInfo(barcode: string): Observable<Ticket> {
    this.log.info(`VuHttpBaseService. getTicketInfo: ${barcode}`);
    return of(null);
  }

  activateOneDayTicket(ticket_code: string): Observable<Ticket> {
    this.log.info(`VuHttpBaseService. activateOneDayTicket: ${ticket_code}`);
    return of(null);
  }

  printTicket(ticket_code: string): Promise<any> {
    this.log.info(`VuHttpBaseService. printTicket: ${ticket_code}`);
    return this.returnEmptyPromise();
  }

  callStaff() {
    this.log.info(`VuHttpBaseService. callStaff.`);
  }

  get isUseProductionApi() {
    return this._useProductionApi;
  }

  set isUseProductionApi(x: boolean) {
    this.log.info(`isUseProductionApi: ${x}`);
    this._useProductionApi = x;
  }

  protected get log(): LoggingService {
    if (!this._log) {
      this._log = this.injector.get(LoggingService);
    }
    return this._log;
  }

  protected parseProducts(json: any): Product[] {
    let result: Product[] = [];
    try {
      for (let item of json) {
        let p = Product.fromJson(item);
        result.push(p);
      }
    } catch (e) {
      this.log.error(`parseProducts. '${e.message}'. response: '${JSON.stringify(json)}'`);
    }
    return result;
  }

  protected parseVisualItems(json: any, type: string): VisualItem[] {
    let visualItems: VisualItem[] = [];
    try {
      for (let jsonItem of json) {
        let item = VisualItem.fromJSONObject(jsonItem, type, this.log);
        if (item != null) {
          visualItems.push(item);
        }
      }
      visualItems.sort((a, b) => a.sequence - b.sequence);
    } catch (e) {
      this.log.error(`parseVisualItems. '${e.message}'. response: '${JSON.stringify(json)}'`);
    }

    return visualItems;
  }

  protected parseDisplayConfiguration(json: any): DisplayConfiguration {

    try {
      return DisplayConfiguration.fromOther(json);
    } catch (e) {
      this.log.error(`parseDisplayConfiguration. '${e.message}'. response: '${JSON.stringify(json)}'`);
    }

    return null;
  }

  protected parseAcceptedCash(json: any): AcceptedCash {
    let result = new AcceptedCash([], []);
    try {
      result.coins = Money.toMoneyArray(json.acceptedCoins);
      result.banknotes = Money.toMoneyArray(json.acceptedBanknotes);
    } catch (e) {
      this.log.error(`parseVisualItems. '${e.message}'. response: '${JSON.stringify(json)}'`);
    }
    return result;
  }

  protected parseAcceptedCreditCards(json: any): CreditCard[] {
    let result: CreditCard[] = [];
    try {
      for (let item of json) {
        let p = CreditCard.fromJson(item);
        result.push(p);
      }
    } catch (e) {
      this.log.error(`parseTicketUse. '${e.message}'. response: '${JSON.stringify(json)}'`);
    }
    return result;
  }

  protected parseTicketUse(json: any): TicketUse[] {
    let result: TicketUse[] = [];
    try {
      for (let item of json) {
        let p = TicketUse.fromJson(item);
        result.push(p);
      }
    } catch (e) {
      this.log.error(`parseTicketUse. '${e.message}'. response: '${JSON.stringify(json)}'`);
    }
    return result;
  }

  protected handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  protected returnEmptyPromise(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      resolve();
    });
  }

  getScreenSaverConfiguration(): Promise<ScreenSaverConfiguration> {
    return new Promise<any>((resolve, reject) => {
      resolve();
    });
  }

  setScreenSaverConfiguration(screenSaverConfiguration: ScreenSaverConfiguration): void {
  }

  openEnter(): Observable<boolean> {
    this.log.info(`VuHttpBaseService. openEnter`);
    return of(false);
  }

  closeEnter(): Observable<boolean> {
    this.log.info(`VuHttpBaseService. closeEnter`);
    return of(false);
  }

  demoPayment(): Observable<any> {
    return of(false);
  }

  changeTouchTileColor(touchTileColor: TouchTileColor[]): Observable<boolean> {
    this.log.info(`VuHttpBaseService. changeTouchTileColor: ${touchTileColor}`);
    return of(false);
  }

  changeTouchTileSwitch(touchTileSwitch: TouchTileSwitch[]): Observable<boolean> {
    this.log.info(`VuHttpBaseService. changeTouchTileSwitch: ${touchTileSwitch}`);
    return of(false);
  }

  changeTouchTileManualMode(enabled: boolean): Observable<boolean> {
    this.log.info(`VuHttpBaseService. changeTouchTileManualMode: ${enabled}`);
    return of(false);
  }

  setRunningLight(runningLightScenario: RunningLightScenario): Observable<boolean> {
    this.log.info(`VuHttpBaseService. runningLightScenario: ${runningLightScenario}`);
    return of(false);
  }

  getCreditCardTerminalState(): Observable<CreditCardTerminalState> {
    this.log.info(`VuHttpBaseService. getCreditCardTerminalState`);
    return of(new CreditCardTerminalState());
  }

  gateReadBarcode(barcode: string, baseUrl: string, openGateType: string, timeout: number): Observable<any> {
    this.log.info(`VuHttpBaseService. gateReadBarcode. barcode: ${barcode}, baseUrl: ${baseUrl}, openGateType: ${openGateType}, timeout: ${timeout}`);
    return of(false);
  }

  changeServerLanguage(locale_id: string) {
    this.log.info(`VuHttpBaseService. changeServerLanguage. locale_id: ${locale_id}`);
  }

  cioBoardAction(outputPinName: CIOBoardPinEvent[]): Promise<any> {
    this.log.info(`VuHttpBaseService. cioBoardAction. action: ${JSON.stringify(outputPinName)}`);
    return this.returnEmptyPromise();
  }

  writeLogMessages(messages: string[]): Promise<any>  {
    return this.returnEmptyPromise();
  }

  forceStopCardTerminal(): void {
    this.log.info(`VuHttpBaseService. forceStopCardTerminal.`);
  }
}
