import { Injectable, Injector } from '@angular/core';
import { AssetsService } from '../assets.service';
import { LanguageService } from '../language.service';
import { DisplayItem } from '../../lib/display/display-item/display-item';
import { DisplayItemTypeEnum } from '../../lib/display/display-item/display-item-type.enum';

@Injectable()
export class DisplayItemAssetsService {

  constructor(
    private assetsService: AssetsService,
    private languageService: LanguageService,
  ) {

  }

  buildUrl(displayItem: DisplayItem): string {
    if (displayItem) {
      if (displayItem.apiUrl) {
        return this.assetsService.getUrl(displayItem.apiUrl, this.languageService.getLanguage());
      }

      if (displayItem.backgroundId) {
        const url = this.assetsService.getAttachmentUrlById(displayItem.backgroundId, this.languageService.getLanguage());
        if (url) {
          return url;
        }
        return this.assetsService.getOdooAttachmentUrlById(
          displayItem.backgroundId,
          this.getFileName(displayItem),
          this.languageService.getLanguage()
        );
      }
    }

    return '';
  }

  buildImageUrl(displayItem: DisplayItem): string {
    if (displayItem) {
      const url = this.buildUrl(displayItem);
      if (url) {
        return url;
      }

      if (displayItem.backgroundId) {
        return this.assetsService.getImageUrlById(displayItem.backgroundId);
      }
    }

    return '';
  }

  getFileName(displayItem: DisplayItem): string {
    return displayItem && displayItem.type === DisplayItemTypeEnum.Markdown ? 'text.md' : null;
  }
}
