import { Injectable, OnDestroy, EventEmitter } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { OutputInputService } from '../../../../output-input/output-input.service';
import { BaseGridWorkflowService } from '../../base/base-grid-workflow.service';
import { DisplayItem } from '../../../../../lib/display/display-item/display-item';
import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';

@Injectable()
export class InputGridWorkflowService extends BaseGridWorkflowService {
  private outputInputService: OutputInputService;
  private subscription: Subscription;
  private focusedDisplayItem: DisplayItem;
  private allUniqueName: Set<string> = new Set<string>();
  keyEventRecived: EventEmitter<{ value: string, action: any }> = new EventEmitter<{ value: string, action: any }>();

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.InputText;
  }

  init(
  ) {
    super.init();
    this.outputInputService = this.injector.get(OutputInputService);
  }

  show(displayItem: DisplayItem, context: any): void {
    this.subscription = this.outputInputService.getKeyEvent().subscribe((keyEvent: { value: string, action: any }) => {
      if (this.focusedDisplayItem) {
        keyEvent['displayItemId'] = this.focusedDisplayItem.id;
        this.keyEventRecived.emit(keyEvent);
      }
    });
    super.show(displayItem, context);
  }

  hide(displayItem: DisplayItem, context: any): void {
    super.hide(displayItem, context);
    this.allUnsubcribe();
  }

  hideAll(): void {
    super.hideAll();
    this.allUnsubcribe();
  }

  allUnsubcribe(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  addContext(uniqueName: string, text: string) {
    if (!uniqueName) {
      return;
    }
    this.allUniqueName.add(uniqueName);
    const context = {};
    context[uniqueName] = text;
    this.displayGridWorkflowService.updateContext(context);
  }

  сhangeFocusedDisplayItem(displayItem: DisplayItem) {
    this.focusedDisplayItem = displayItem;
  }

  fillExternalApiRequestData(requestData: Map<string, any>, context: any) {
    if (context == null) {
      return;
    }
    if (!this.allUniqueName && this.allUniqueName.size === 0) {
      return;
    }

    this.allUniqueName.forEach((uniqueName: string) => {
      if (context[uniqueName] != null) {
        requestData.set(uniqueName, context[uniqueName]);
      }
    });
  }

  getValueByUniqueName(uniqueName: string): string {
    let result = '';
    if (uniqueName && Object.keys(this.context).length > 0) {
      if (typeof (this.context) === 'object') {
        result = this.context.hasOwnProperty(uniqueName) ? this.context[uniqueName] : '';
      }
    }
    return result;
  }

  enableNextButton(enabled: boolean) {
    this.displayGridWorkflowService.changeIsDisabledState('input_complete_button', !enabled);
  }
}
