import { DisplayItem } from '../display-item/display-item';
import { Product } from '../../product';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';


export class DisplayItemTree {

  private internalNodesItems: DisplayItem[] = [];
  private internalAllitems: DisplayItem[] = [];

  static build(itemsData: any, itemsTreeData: any, additionalItemsTree: any, existingDisplayItems: DisplayItem[] = null): DisplayItemTree {
    const displayItemBuilder = new DisplayItemTree();
    displayItemBuilder.rebuildTree(itemsData, itemsTreeData, additionalItemsTree, existingDisplayItems);
    return displayItemBuilder;
  }

  get nodes(): DisplayItem[] {
    return this.internalNodesItems;
  }

  get items(): DisplayItem[] {
    return this.internalAllitems;
  }

  rebuildTree(itemsData: any, itemsTreeData: any, additionalItemsTree: any, existingDisplayItems: DisplayItem[] = null): void {
    this.internalAllitems = DisplayItem.fromArrayJson(itemsData);
    const nodes = {};
    this.internalAllitems.forEach(element => {
      nodes[element.id] = element;
    });
    const processedNodes = {};
    this.internalNodesItems = this._fillLinks(itemsTreeData, additionalItemsTree, nodes, existingDisplayItems, processedNodes);
  }

  private _fillLinks(
    itemsTree: any, additionalItemsTree: any, nodes: any, existingDisplayItems: DisplayItem[], processedNodes: any): DisplayItem[] {
    if (itemsTree == null || itemsTree.length === 0) {
      return null;
    }

    const result = new Array<DisplayItem>();

    itemsTree.forEach(element => {
      const item_id = element.item_id;
      if (!item_id) {
        return;
      }

      const node = nodes[item_id] as DisplayItem;
      if (!node) {
        if (existingDisplayItems) {
          let existingDisplayItem = null;
          existingDisplayItems.forEach(displayItems => {
            if (displayItems && displayItems.id === item_id) {
              existingDisplayItem = displayItems;
            }
          });
          if (existingDisplayItem) {
            result.push(existingDisplayItem);
            return;
          }
        } else {
          return;
        }
      }

      const processedNode = processedNodes[item_id];
      if (processedNode) {
        result.push(processedNode);
        return;
      }

      processedNodes[item_id] = node;

      if (element.items && !node.children) {
        node.children = this._fillLinks(element.items, additionalItemsTree, nodes, existingDisplayItems, processedNodes);
      }

      if (node.navigationId) {
        node.navigationItem = this._fillDisplayItem(node.navigationId, additionalItemsTree, nodes, existingDisplayItems, processedNodes);
      }

      if (node.failureId) {
        node.failureItem = this._fillDisplayItem(node.failureId, additionalItemsTree, nodes, existingDisplayItems, processedNodes);
      }

      result.push(node);
    });

    return result;
  }

  private _fillDisplayItem(displayId: number, additionalItemsTree: any, nodes: any, existingDisplayItems: any, processedNodes: any) {
    const processedNode = processedNodes[displayId];
    if (processedNode) {
      return processedNode;
    }

    let displayItem = nodes[displayId] as DisplayItem;
    if (additionalItemsTree && displayItem) {

      const childrenItemsTree = this._getChildrenItemsTree(displayItem.id, additionalItemsTree);
      if (!displayItem.children && childrenItemsTree) {
        displayItem.children = this._fillLinks(childrenItemsTree, additionalItemsTree, nodes, existingDisplayItems, processedNodes);
      }

      if ((displayItem.navigationId && !displayItem.navigationItem) ||
        (displayItem.failureId && !displayItem.failureItem)
      ) {
        const subTree = additionalItemsTree.find(item => item.item_id === displayItem.id);
        if (subTree) {
          this._fillLinks([subTree], additionalItemsTree, nodes, existingDisplayItems, processedNodes);
        }
      }
    }

    if (!displayItem) {
      existingDisplayItems.forEach(existingDisplayItem => {
        if (existingDisplayItem && existingDisplayItem.id === displayId) {
          displayItem = existingDisplayItem;
        }
      });
    }

    return displayItem;
  }

  private _getChildrenItemsTree(displayItemId, itemsTree) {
    for (const element of itemsTree) {
      if (!element || !element.item_id) {
        continue;
      }

      if (element.item_id === displayItemId) {
        return element.items;
      }

      if (element.items) {
        const foundItemsTree = this._getChildrenItemsTree(displayItemId, element.items);
        if (foundItemsTree) {
          return foundItemsTree;
        }
      }
    }

    return null;
  }

  private get _productsIds(): number[] {
    const productIds = new Set<number>();
    this.internalAllitems.forEach(element => {
      if (element.productId) {
        productIds.add(element.productId);
      }
    });

    return Array.from(productIds.values());
  }

  private _fillProducts(products: Product[]) {
    const productDict = {};
    if (products) {
      products.forEach(element => {
        if (element && element.id) {
          productDict[element.id] = element;
        }
      });
    }

    this.internalAllitems.forEach(element => {
      element.product = element.productId ? productDict[element.productId] : null;
    });
  }

  get itemsIds(): number[] {
    return this.internalAllitems.map(item => item.id);
  }

  fillProducts(vuCommunicationService: VuCommunicationService): Promise<any> {
    return new Promise<DisplayItemTree>(
      (resolve, reject) => {
        if (!vuCommunicationService) {
          reject();
        }

        const productsIds = this._productsIds;
        if (!productsIds || productsIds.length === 0) {
          resolve();
        }

        vuCommunicationService.vuHttp.getProductsByIds(productsIds).
          then(
            products => {
              this._fillProducts(products);
              resolve();
            }
          );
      });
  }
}
