import { Component } from '@angular/core';
import { BaseGridViewerComponent } from '../../base/base-grid-viewer-component';

@Component({
  selector: 'app-display-item-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.css']
})
export class DisplayItemImageViewerComponent  extends BaseGridViewerComponent {

  buildUrl(): string {
    return this.displayItemAssetsService.buildImageUrl(this.displayItem);
  }
}
