import { Action } from '@ngrx/store';
import { Configuration } from '../lib/lib';
import { PayloadAction } from '../lib/payload-action';

const initialState = new Configuration();

export class ConfigurationActionType {
  static readonly CONFIGURATION_UPDATE = 'ConfigurationUpdate';
  static readonly CONFIGURATION_VU_ROLE = 'ConfigurationVuRole';
  static readonly CONFIGURATION_LOCALE = 'ConfigurationLocale';
  static readonly CONFIGURATION_SHOW_ARTICLES_ON_MAIN_PAGE = 'ConfigurationShowArticlesOnMainPage';
  static readonly CONFIGURATION_PRINT_CARD_TERMINAL_RECEIPT = 'ConfigurationPrintCardTerminalReceipt';
  static readonly CONFIGURATION_PRINT_ORDER_RECEIPT = 'ConfigurationPrintOrderReceipt';
  static readonly CONFIGURATION_ONE_LINE_ARTICLE_SALE_MODE = 'ConfigurationOneLineArticleSaleMode';
  static readonly CONFIGURATION_BARCODE_INPUT_METHOD = 'ConfigurationBarcodeInputMethod';
  static readonly CONFIGURATION_TICKET_PRINTER_TYPE = 'ConfigurationTicketPrinterType';
  static readonly CONFIGURATION_RECEIPT_PRINTER_TYPE = 'ConfigurationReceiptPrinterType';
  static readonly CONFIGURATION_ADDITIONAL_PROPERTIES = 'ConfigurationAdditionalProperties';
  static readonly CONFIGURATION_DISPLAY_CONFIGURATION = 'ConfigurationDisplayConfiguration';
  static readonly CONFIGURATION_DISPLAY_CONFIGURATION_ID = 'ConfigurationDisplayConfigurationId';
  static readonly CONFIGURATION_CUSTOM_CSS = 'ConfigurationCustomCss';
  static readonly CONFIGURATION_CUSTOM_LOGO_ID = 'ConfigurationCustomLogoId';
  static readonly CONFIGURATION_BACKGROUND_ID = 'ConfigurationBackgroundId';
  static readonly CONFIGURATION_CHANGE_DATE = 'ChangeDate';
}

export function configurationReducer(state = initialState, action: PayloadAction) {
  let isChanged = true;
  switch (action.type) {
    case ConfigurationActionType.CONFIGURATION_VU_ROLE:
      if (state.vuRole === action.payload) {
        isChanged = false;
      }
      state.vuRole = action.payload;
      break;
    case ConfigurationActionType.CONFIGURATION_LOCALE:
      if (state.locale === action.payload) {
        isChanged = false;
      }
      state.locale = action.payload;
      break;
    case ConfigurationActionType.CONFIGURATION_SHOW_ARTICLES_ON_MAIN_PAGE:
      if (state.showArticlesOnMainPage === action.payload) {
        isChanged = false;
      }
      state.showArticlesOnMainPage = action.payload;
      break;
    case ConfigurationActionType.CONFIGURATION_PRINT_CARD_TERMINAL_RECEIPT:
      if (state.printCardTerminalReceipt === action.payload) {
        isChanged = false;
      }
      state.printCardTerminalReceipt = action.payload;
      break;
    case ConfigurationActionType.CONFIGURATION_PRINT_ORDER_RECEIPT:
      if (state.printOrderReceipt === action.payload) {
        isChanged = false;
      }
      state.printOrderReceipt = action.payload;
      break;
    case ConfigurationActionType.CONFIGURATION_ONE_LINE_ARTICLE_SALE_MODE:
      if (state.oneLineArticleSaleMode === action.payload) {
        isChanged = false;
      }
      state.oneLineArticleSaleMode = action.payload;
      break;
    case ConfigurationActionType.CONFIGURATION_UPDATE:
      state = Configuration.fromOther(action.payload);
      break;
    case ConfigurationActionType.CONFIGURATION_BARCODE_INPUT_METHOD:
      if (state.barcodeInputMethod === action.payload) {
        isChanged = false;
      }
      state.barcodeInputMethod = action.payload;
      break;
    case ConfigurationActionType.CONFIGURATION_TICKET_PRINTER_TYPE:
      if (state.ticketPrinterType === action.payload) {
        isChanged = false;
      }
      state.ticketPrinterType = action.payload;
      break;
    case ConfigurationActionType.CONFIGURATION_RECEIPT_PRINTER_TYPE:
      if (state.receiptPrinterType === action.payload) {
        isChanged = false;
      }
      state.receiptPrinterType = action.payload;
      break;
    case ConfigurationActionType.CONFIGURATION_ADDITIONAL_PROPERTIES:
      if (state.additionalProperties === action.payload) {
        isChanged = false;
      }
      state.additionalProperties = action.payload;
      break;
    case ConfigurationActionType.CONFIGURATION_DISPLAY_CONFIGURATION:
      if (state.displayConfiguration === action.payload) {
        isChanged = false;
      }
      state.displayConfiguration = action.payload;
      break;
    case ConfigurationActionType.CONFIGURATION_DISPLAY_CONFIGURATION_ID:
      if (state.displayConfigurationId === action.payload) {
        isChanged = false;
      }
      state.displayConfigurationId = action.payload;
      break;
    case ConfigurationActionType.CONFIGURATION_CUSTOM_CSS:
      if (state.customCss === action.payload) {
        isChanged = false;
      }
      state.customCss = action.payload;
      break;
    case ConfigurationActionType.CONFIGURATION_CUSTOM_LOGO_ID:
      if (state.customLogoId === action.payload) {
        isChanged = false;
      }
      state.customLogoId = action.payload;
      break;
    case ConfigurationActionType.CONFIGURATION_BACKGROUND_ID:
      if (state.backgroundId === action.payload) {
        isChanged = false;
      }
      state.backgroundId = action.payload;
      break;
    case ConfigurationActionType.CONFIGURATION_CHANGE_DATE:
      if (state.changeDate === action.payload) {
        isChanged = false;
      }
      state.changeDate = action.payload;
      break;
    default:
      isChanged = false;
      break;
  }
  return isChanged ? Configuration.fromOther(state) : state;
}
