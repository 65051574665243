import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { DisplayItem } from '../../../../lib/display/display-item/display-item';

@Component({
  selector: 'app-grid-display-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css']
})
export class DisplayItemComponent implements OnInit {

  @Input()
  displayItem: DisplayItem;

  @Output()
  onClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(
  ) {
  }

  ngOnInit() {
  }

  onItemClick() {
    if (this.displayItem != null && this.displayItem != undefined) {
      this.onClick.emit(this.displayItem);
    }
  }

  setName(item: DisplayItem) {
    return item.name + ` ${item.sizeHorizontal - item.posX}x` + `${item.sizeVertical - item.posY}`;
  }
}
