import { Injectable } from '@angular/core';

import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';
import { DisplayItem } from '../../../../../lib/display/display-item/display-item';
import { BaseGridWorkflowService } from '../../base/base-grid-workflow.service';
import { Subscription } from 'rxjs';
import { SaleService } from '../../../../sale.service';

@Injectable()
export class CardPaymentGridWorkflowService extends BaseGridWorkflowService {

  subsrcription: Subscription;
  private saleService: SaleService;

  init(
  ) {
    super.init();
    this.saleService = this.injector.get(SaleService);
    this.dispatcherService.onDisplayModeCardPaymentComplete((message) => {
      if (!this.displayItem) {
        return;
      }

      this.userActivity();

      const info = message.info !== undefined ? message.info : false;
      if (info) {
        this.paymentComplete();
      } else {
        this.saleService.closePaymentSession();
        this.displayGridWorkflowService.gridVisualItemsComponent.backPage();
      }
    });
  }

  paymentComplete() {
    this.navigateToUrlOrNavigationItem(this.displayItem);
    this.delayedHardReset();
  }

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.CardPayment;
  }

  show(displayItem: DisplayItem, context: any): void {
    this.displayGridWorkflowService.displayGridInactivityService.disableInactivityTimer();
    super.show(displayItem, context);
    this.dispatcherService.toDisplayModeCardPayment(false);
  }

  hide(displayItem: DisplayItem, context: any) {
    super.hide(displayItem, context);
  }

  get hardResetProccessDisplayItem(): DisplayItem {
    // TODO Turn off card terminal
    return null;
  }
}
