import { Component, OnInit } from '@angular/core';
import { DisplayConfigurationSimulatorService } from '../../../services/display/configuration/display-configuration-simulator.service';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';
import { DisplayConfigurationExtendedDisplayEnum } from '../../../lib/display/configuration/display-configuration-extended-display.enum';

@Component({
  selector: 'app-display-configuration-simulator',
  templateUrl: './display-configuration-simulator.component.html',
  styleUrls: ['./display-configuration-simulator.component.css']
})
export class DisplayConfigurationSimulatorComponent implements OnInit {
  extendedDisplays: string[];

  constructor(
    private displayConfigurationSimulatorService: DisplayConfigurationSimulatorService,
    private vuCommunicationService: VuCommunicationService,
  ) { }

  ngOnInit() {
    this.extendedDisplays = Object.values(DisplayConfigurationExtendedDisplayEnum);
  }

  get rowCount(): number {
    return this.displayConfigurationSimulatorService.rowCount;
  }

  set rowCount(value: number) {
    this.displayConfigurationSimulatorService.rowCount = value;
    this._updateGridDisplayConfiguration();
  }

  get columnsCount(): number {
    return this.displayConfigurationSimulatorService.columnsCount;
  }

  set columnsCount(value: number) {
    this.displayConfigurationSimulatorService.columnsCount = value;
    this._updateGridDisplayConfiguration();
  }

  get rowNumbers(): number[] {
    return Array.apply(null, { length: 101 }).map(Number.call, Number);
  }

  get columnsNumbers(): number[] {
    return Array.apply(null, { length: 101 }).map(Number.call, Number);
  }

  get configurationId(): number {
    return this.displayConfigurationSimulatorService.configurationId;
  }

  set configurationId(value: number) {
    this.displayConfigurationSimulatorService.configurationId = value;
    this._updateGridDisplayConfiguration();
  }

  get configurationCount(): number[] {
    return Array.apply(null, { length: 3 }).map(Number.call, Number);
  }

  get extendedDisplay(): DisplayConfigurationExtendedDisplayEnum {
    return this.displayConfigurationSimulatorService.extendedDisplay;
  }

  set extendedDisplay(value: DisplayConfigurationExtendedDisplayEnum) {
    this.displayConfigurationSimulatorService.extendedDisplay = value;
    this._updateGridDisplayConfiguration();
  }

  _updateGridDisplayConfiguration() {
    this.vuCommunicationService.vuHttp.getVuConfiguration().then(
      result => {
        result.displayConfigurationId = this.configurationId;
        this.vuCommunicationService.vuConnectionSimulator.vuConfigurationChanged(result);
      }
    );
  }
}
