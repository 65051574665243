import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DisplayItem } from '../../../../lib/display/display-item/display-item';
import { InputGridWorkflowService } from '../../../../services/display/grid/text-input/input/input-grid-workflow.service';
import { Subscription } from 'rxjs';
import { KeyboardHelper } from '../../../../lib/helpers/keyboard-helper';
import { LanguageService } from '../../../../services/language.service';

@Component({
  selector: 'app-display-item-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css']
})
export class DisplayItemInputComponent implements OnInit, OnDestroy {
  @Input()
  displayItem: DisplayItem;

  textInput = '';
  keyEventSubscriber: Subscription;
  maxTextInputLength = 10;
  minTextInputAction = 3;

  private keyboardHelper: KeyboardHelper;

  constructor(
    private inputGridWorkflowService: InputGridWorkflowService,
    private languageService: LanguageService,
  ) {
    this.keyboardHelper = new KeyboardHelper(null);
  }

  ngOnInit() {
    this.onFocus();
    this.textInput = this.inputGridWorkflowService.getValueByUniqueName(this.displayItem.uniqueName);
    this.onInputComplete();
    this.keyEventSubscriber = this.inputGridWorkflowService.keyEventRecived
      .subscribe((keyEvent: { char: string, action: any, displayItemId: number }) => {
        this.textChanged(keyEvent);
      });
    this.installStartVariable();
  }

  ngOnDestroy(): void {
    this.textInput = '';
    this.keyEventSubscriber.unsubscribe();
  }

  private textChanged(keyEvent: { char: string, action: any, displayItemId: number }) {
    if (this.textInput.length >= this.maxTextInputLength && keyEvent.action !== 'del') {
      return;
    }
    if (keyEvent.displayItemId !== this.displayItem.id || !this.displayItem.uniqueName) {
      return;
    }
    this.textInput = this.keyboardHelper.keyPress(
      this.textInput,
      {
        value: keyEvent.char,
        action: keyEvent.action
      },
      null);
    this.inputGridWorkflowService.addContext(this.displayItem.uniqueName, this.textInput);
    this.onInputComplete();
  }

  onFocus(): void {
    this.inputGridWorkflowService.сhangeFocusedDisplayItem(this.displayItem);
  }

  updateCaretPos(input: any) {
    this.keyboardHelper.updateCaretPos(input);
  }

  private onInputComplete(): void {
    if (this.textInput.length >= this.minTextInputAction) {
      this.inputGridWorkflowService.enableNextButton(true);
    } else {
      this.inputGridWorkflowService.enableNextButton(false);
    }
  }

  private installStartVariable(): void {
    if (!this.displayItem) {
      return;
    }
    this.maxTextInputLength = this.displayItem.textLength
      ? this.displayItem.textLength : this.maxTextInputLength;
    this.minTextInputAction = this.displayItem.minTextInputAction
      ? this.displayItem.minTextInputAction : this.minTextInputAction;
  }

  get language(): string {
    return this.languageService.odooLanguageName;
  }

  get placeholder() {
    return this.displayItem.translateInputPlacegolder(this.language);
  }

}
