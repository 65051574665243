export class Utilites {

  static areArraysEqual(a: any, b: any) {
    if (a === b) { return true; }
    if (a == null || b == null) { return false; }

    if (a.length !== b.length) { return false; }

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) { return false; }
    }
    return true;
  }

  static strMapToObj(strMap: any) {
    const obj = Object.create(null);
    for (const [k, v] of strMap) {
      obj[k] = v;
    }
    return obj;
  }
}
