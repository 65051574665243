import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration/configuration.service';
import { Asset } from '../lib/assets/asset';
import { ENGLISH_LANGUAGE_ID, Locale } from '../lib/lib';

@Injectable()
export class AssetsService {

  readonly assetsRegex = /assets:\/\/file_(\d+)/g;

  constructor(
    private configurationService: ConfigurationService,
  ) {
  }

  get assets(): Asset[] {
    return this.configurationService.configuration.assets;
  }

  getBackgroundImagesPathUrl() {
    return '../../static/images/info/';
  }

  getImageUrlById(imageId: number): string {
    return this.getFullUrl(this.getBackgroundImageName(imageId));
  }

  getBackgroundImageName(imageId: number): string {
    if (imageId < 0) {
      return `image_demo${Math.abs(imageId)}.jpg`;
    }

    return `image_${imageId}.jpg`;
  }

  getCategoryImageUrl(categoryId: number): string {
    return this.getFullUrl(this.getBackgroundCategoryImageName(categoryId));
  }

  getBackgroundCategoryImageName(categoryId: number): string {
    return `category_${categoryId}.jpg`;
  }

  getFullUrl(fileName: string) {
    return this.getBackgroundImagesPathUrl() + fileName;
  }

  getAttachmentUrlById(attachmentId: number, lang: string): string {
    const fileName = this.getAttachmentName(attachmentId, lang);
    if (fileName) {
      return this.getFullUrl(fileName);
    }

    return null;
  }

  getAttachmentName(attachmentId: number, lang: string): string {

    if (!this.assets || this.assets.length === 0) {
      return null;
    }

    const attachmentIdString = attachmentId.toString();

    const attachment = this.assets.find(item => item.id === attachmentIdString);
    if (attachment == null || attachment.fileName == null) {
      return null;
    }

    const fileExt = attachment.fileName.split('.').pop();
    if (fileExt == null) {
      return null;
    }

    let langFile = this.getLangExtention(attachment, lang);
    if (langFile) {
      langFile = '_' + langFile;
    }

    return `attachment_${attachmentId}${langFile}.${fileExt}`;
  }

  getLangExtention(attachment: Asset, lang: string): string {

    if (!attachment || !attachment.isSupportedLanguagesExists) {
      return '';
    }

    const odooLang = Locale.convertToOdooLanguageId(lang);
    if (attachment.isLanguageSupported(odooLang)) {
      return odooLang;
    }

    const defaultOdooLang = Locale.convertToOdooLanguageId(ENGLISH_LANGUAGE_ID);
    if (attachment.isLanguageSupported(defaultOdooLang)) {
      return defaultOdooLang;
    }

    return attachment.tryGetFirstSupportedLanguage;
  }

  replaceAssetsUrls(htmlTemplate: string, language: string): string {
    const cacheUrls = new Map<string, string>();
    return htmlTemplate.replace(this.assetsRegex, (match, fileId) => {
      if (cacheUrls.has(fileId)) {
        return cacheUrls.get(fileId);
      }
      const numberFileId = +fileId;
      const url = this.getAttachmentUrlById(numberFileId, language);
      cacheUrls.set(fileId, url);
      return url;
    });
  }

  getUrl(url: string, language: string): string {
    if (!url) {
      return url;
    }
    return url.replace(this.assetsRegex, (match, fileId) => {
      const numberFileId = +fileId;
      const fileName = this.getAttachmentName(numberFileId, language);
      return this.getOdooFullUrl(fileName);
    });
  }

  getOdooFullUrl(fileName: string) {
    return this.getOdooAssetsPathUrl() + fileName;
  }

  getOdooAssetsPathUrl(): string {
    return this.getOdooBaseUrl() + '/api/attachments/';
  }

  getOdooBaseUrl(): string {
    return this.configurationService.configuration.odooUrl;
  }

  getOdooAttachmentUrlById(attachmentId: number, fileName: string, lang: string): string {
    let apiUrl = `${attachmentId}`;

    if (fileName) {
      apiUrl += '/' + fileName;
    }

    if (lang) {
      apiUrl += '?lang=' + Locale.convertToOdooLanguageId(lang);
    }

    return this.getOdooFullUrl(apiUrl);
  }
}
