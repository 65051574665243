import { Injectable } from '@angular/core';

import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';
import { BaseViewerGridWorkflowService } from '../base-viewer-grid-workflow.service';

@Injectable()
export class ImageViewerGridWorkflowService extends BaseViewerGridWorkflowService {

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.Image;
  }

  enableNextButton(enabled: boolean) {
    super.enableNextButton(true);
  }
}
