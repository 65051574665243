import { PinState } from './pin-state';
import { CIOBoardPinEvent } from './cioboard-pin-event';

export class CIOBoardEvent {

  constructor(
    public oldState: PinState,
    public newState: PinState
  ) {
  }

  static fromOther(other: any) {
    return new CIOBoardEvent(
      PinState.fromOther(other.oldState),
      PinState.fromOther(other.newState)
    );
  }

  toString(): string {
    let result = `oldState: ${this.oldState}.`;
    result += `newState: ${this.newState}.`;
    return result;
  }

  isOnePinsChanged(cioBoardPins: CIOBoardPinEvent[]): boolean {
    if (!cioBoardPins || !this.newState) {
      return false;
    }

    return cioBoardPins.some(item => this.isPinChanged(item));
  }

  isAllPinsChanged(cioBoardPins: CIOBoardPinEvent[]): boolean {
    if (!cioBoardPins || !this.newState) {
      return false;
    }

    return cioBoardPins.every(item => this.isPinChanged(item));
  }

  isPinChanged(cioBoardPin: CIOBoardPinEvent): boolean {
    if (!cioBoardPin || !cioBoardPin.name || !this.newState) {
      return false;
    }

    const pinName = cioBoardPin.name.toLowerCase();

    return (!this.oldState || this.oldState[pinName] !== this.newState[pinName])
      && this.newState[pinName] === cioBoardPin.state;
  }
}
