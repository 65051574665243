import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DisplayItem } from '../../../../lib/display/display-item/display-item';

@Component({
  selector: 'app-display-item-card-payment',
  templateUrl: './card-payment.component.html',
  styleUrls: ['./card-payment.component.css']
})
export class DisplayItemCardPaymentComponent implements OnInit {
  @Input()
  displayItem: DisplayItem;

  @Output()
  onClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(
  ) {

  }

  ngOnInit() {
  }

}
