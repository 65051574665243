import * as StateMachine from 'javascript-state-machine/dist/state-machine.js';
import { ILog } from '../../services/logging/log.interface';
import { ExtendedLog } from '../extended-log';
import { Subject } from 'rxjs';
import { Money } from '../money/money';
import { CreditCardTerminalState } from './credit-card-terminal-state';
import { CreditCardTerminalEvent } from './credit-card-terminal-event';
import { CreditCardTerminalEventType } from './credit-card-terminal-event-type';
import { CredtitCardReceipt } from './credit-card-receipt';

const STEP_WAIT_TIME = 1000;

export class CardTerminalSimulator {
  protected machine: any;
  private log: ExtendedLog;
  private _creditCardTerminalState = new CreditCardTerminalState();
  eventCreditCardTerminalEvent = new Subject<CreditCardTerminalEvent>();

  constructor(log: ILog) {
    this.log = new ExtendedLog('CardTerminalSimulator', log);
    this.machine = new StateMachine({
      init: 'off',
      transitions: this.getTransitions(),
      methods: this.getMethods()
    });
  }

  protected getTransitions(): any[] {
    return [
      {
        name: 'toIdle',
        from: [
          'off',
          'waitingForCard',
          'canceled',
          'waitingForCardRemoval',
          'paymentCompleted',
          'revertingTransaction',
        ],
        to: 'idle'
      },
      {
        name: 'toTransactionBegin',
        from: ['idle'],
        to: 'transactionBegin'
      },
      {
        name: 'toRemovePrevCard',
        from: ['*'],
        to: 'removePrevCard'
      },
      {
        name: 'toWaitingForCard',
        from: ['*'],
        to: 'waitingForCard'
      },
      {
        name: 'toEvaluatingCard',
        from: ['*'],
        to: 'evaluatingCard'
      },
      {
        name: 'toWaitingForPin',
        from: ['*'],
        to: 'waitingForPin'
      },
      {
        name: 'toInvalidPin',
        from: ['*'],
        to: 'invalidPin'
      },
      {
        name: 'toCanceled',
        from: ['*'],
        to: 'canceled'
      },
      {
        name: 'toWaitingForCardRemoval',
        from: ['*'],
        to: 'waitingForCardRemoval'
      },
      {
        name: 'toProcessingPayment',
        from: ['*'],
        to: 'processingPayment'
      },
      {
        name: 'toPaymentCompleted',
        from: ['*'],
        to: 'paymentCompleted'
      },
      {
        name: 'toOutOfService',
        from: ['*'],
        to: 'outOfService'
      },
      {
        name: 'toRevertingTransaction',
        from: ['*'],
        to: 'revertingTransaction'
      },
      {
        name: 'toOff',
        from: ['*'],
        to: 'off'
      }
    ];
  }

  protected getMethods(): any {
    let scope = this;
    return {
      onToOff: function () { },
      onToIdle: function () {
        scope.doAsync(() => scope.setScreenMessage('Terminal ready'));
      },
      onToTransactionBegin: function () {
        scope.doAsync(() => {
          if (scope.isCardInserted) {
            scope.machine.toRemovePrevCard();
          } else {
            scope.machine.toWaitingForCard();
          }
        });
      },
      onToRemovePrevCard: function () { },
      onToWaitingForCard: function () { },
      onToEvaluatingCard: function () {
        scope.doAsync(() => scope.machine.toWaitingForPin());
      },
      onToWaitingForPin: function () {
        scope.doAsync(() => scope.setScreenMessage('Please enter PIN'));
      },
      onToInvalidPin: function () {
        scope.doAsync(() => {
          scope.setScreenMessage('PIN is invalid');
          setTimeout(() => scope.machine.toWaitingForPin(), STEP_WAIT_TIME);
        });
      },
      onToCanceled: function () {
        scope.doAsync(() => {
          scope.setScreenMessage('Payment canceled. Take the card');
          scope.raiseCreditCardTerminalEvent(CreditCardTerminalEventType.PaymentAborted);
          setTimeout(
            () => scope.machine.toWaitingForCardRemoval(),
            STEP_WAIT_TIME
          );
        });
      },
      onToWaitingForCardRemoval: function () {
        scope.doAsync(() => {
          scope.setScreenMessage('Take the card');
        });
      },
      onToProcessingPayment: function () {
        scope.doAsync(() => {
          scope.setScreenMessage('Please wait...');
          setTimeout(() => scope.machine.toPaymentCompleted(), STEP_WAIT_TIME);
        });
      },
      onToPaymentCompleted: function () {
        scope.doAsync(() => {
          scope.raiseCreditCardTerminalEvent(CreditCardTerminalEventType.ReceiptReceived, CredtitCardReceipt.getReceiptPaymentSuccess());
          scope.setScreenMessage(
            'Payment successfully completed. Take the card'
          );
          scope.raiseCreditCardTerminalEvent(CreditCardTerminalEventType.PaymentCompleted);
          setTimeout(
            () => scope.machine.toWaitingForCardRemoval(),
            STEP_WAIT_TIME
          );
        });
      },
      onAfterTransition: function (event: any) {
        scope.log.info(`${event.transition}. (${event.from} -> ${event.to}).`);
      },
      onInvalidTransition: function (transition: any, from: any, to: any) {
        scope.log.warning(`${transition}. (${from}->?).`);
      }
    };
  }

  get state(): string {
    return this.machine.state;
  }

  start() {
    this.machine.toIdle();
  }

  get isCardInserted(): boolean {
    return this.creditCardTerminalState.isCardInserted;
  }

  set isCardInserted(value: boolean) {
    this.creditCardTerminalState.isCardInserted = value;
  }

  get creditCardTerminalState(): CreditCardTerminalState {
    return this._creditCardTerminalState;
  }

  get isCardLocked(): boolean {
    return !(
      this.state === 'off' ||
      this.state === 'idle' ||
      this.state === 'removePrevCard' ||
      this.state === 'canceled' ||
      this.state === 'paymentCompleted' ||
      this.state === 'waitingForCardRemoval'
    );
  }

  cardInserted() {
    this.isCardInserted = true;
    this.raiseCreditCardTerminalEvent(CreditCardTerminalEventType.CardInserted);
    if (this.state === 'waitingForCard') {
      this.machine.toEvaluatingCard();
    }
  }

  cardRemoved() {
    this.isCardInserted = false;
    this.raiseCreditCardTerminalEvent(CreditCardTerminalEventType.CardRemoved);
    if (this.state === 'removePrevCard') {
      this.machine.toWaitingForCard();
    }
    else if (this.state !== 'idle') {
      this.machine.toIdle();
    }
  }

  beginCardPaymentTransaction(amount: Money) {
    this.machine.toTransactionBegin();
  }

  pinEntered(isValid: boolean) {
    if (isValid) {
      this.machine.toProcessingPayment();
    } else {
      this.machine.toInvalidPin();
    }
  }

  cancel() {
    this.machine.toCanceled();
  }

  private doAsync(f: any) {
    let scope = this;
    setTimeout(function () {
      scope.do(f, `async. ${f}`);
    }, 1);
  }

  private do(f: any, contextMessage: string) {
    try {
      f();
    } catch (e) {
      this.log.error(`do. ${contextMessage}. ${e.message}`);
    }
  }

  private setScreenMessage(x: string) {
    this.raiseCreditCardTerminalEvent(CreditCardTerminalEventType.TerminalTextChanged, x);
  }

  private raiseCreditCardTerminalEvent(eventType: CreditCardTerminalEventType, eventInfo?: string) {
    this.eventCreditCardTerminalEvent.next(new CreditCardTerminalEvent(eventType, eventInfo));
  }

  revertTransaction() {
    this.machine.toRevertingTransaction();
  }

  revertingTransactionCompleted() {
    this.machine.toIdle();
    this.raiseCreditCardTerminalEvent(CreditCardTerminalEventType.RevertTransactionCompleted, '');
  }

  revertingTransactionFailed() {
    this.machine.toIdle();
    this.raiseCreditCardTerminalEvent(CreditCardTerminalEventType.RevertTransactionFailed, '');
  }
}
