import { Injectable, Injector } from '@angular/core';
import { IExternalApiService } from './external-api.interface';
import { LoggingService } from '../../logging/logging.service';
import { LoadingSpinnerService } from '../../loading-spinner/loading-spinner.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigurationService } from '../../configuration/configuration.service';

@Injectable()
export abstract class ExternalApiBaseService implements IExternalApiService {

  protected http: HttpClient;
  protected internalLog: LoggingService;
  protected loadingSpinnerService: LoadingSpinnerService;
  protected configurationService: ConfigurationService;
  constructor(
    protected injector: Injector,
  ) {
    this.init();
  }

  protected init(): void {
    this.http = this.injector.get(HttpClient);
    this.loadingSpinnerService = this.injector.get(LoadingSpinnerService);
    this.configurationService = this.injector.get(ConfigurationService);
  }

  protected get log(): LoggingService {
    if (!this.internalLog) {
      this.internalLog = this.injector.get(LoggingService);
    }
    return this.internalLog;
  }

  get requestTimeoutMs(): number {
    return this.configurationService.configuration.externalApiRequestTimeoutMs;
  }

  sendGetRequest(url: string, requestData: Map<string, any>): Promise<any> {
    this.log.info(`ExternalApiBaseService. sendRequest. Url: ${url}, requestData: ${requestData}`);
    this.loadingSpinnerService.show();
    return this.http
      .get(`${url}${this._buildGetQuery(requestData)}`)
      .timeout(this.requestTimeoutMs)
      .toPromise()
      .then((response) => {
        this.loadingSpinnerService.hide();
        return response;
      })
      .catch((error: any) => {
        this.loadingSpinnerService.hide();
        return this.handleError(error);
      });
  }

  sendPostRequest(url: string, requestData: Map<string, any>): Promise<any> {
    this.log.info(`ExternalApiBaseService. sendPostRequest. Url: ${url}, requestData: ${requestData}`);
    this.loadingSpinnerService.show();
    return this.http
      .post(url, this._buildPostData(requestData))
      .timeout(this.requestTimeoutMs)
      .toPromise()
      .then((response) => {
        this.loadingSpinnerService.hide();
        return response;
      })
      .catch((error: any) => {
        this.loadingSpinnerService.hide();
        return this.handleError(error);
      });
  }

  sendPostRequestOdooJson(url: string, requestData: Map<string, any>): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Openerp-Session-Id': this.configurationService.configuration.odooToken,
      })
    };
    const body = JSON.stringify({ params: this._buildPostData(requestData) });
    this.log.info(`ExternalApiBaseService. sendPostRequestJson. Url: ${url}, requestData: ${body}`);
    this.loadingSpinnerService.show();
    return this.http
      .post(url, body, httpOptions)
      .timeout(this.requestTimeoutMs)
      .toPromise()
      .then((response: any) => {
        this.loadingSpinnerService.hide();
        if (!response) {
          return null;
        }

        if (!response || !response.result) {
          return null;
        }

        return response.result;
      })
      .catch((error: any) => {
        this.loadingSpinnerService.hide();
        return this.handleError(error);
      });
  }

  protected returnEmptyPromise(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      resolve();
    });
  }

  protected handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error);
  }

  protected _buildGetQuery(requestData: Map<string, any>): string {
    const queryParams: string[] = new Array<string>();

    requestData.forEach((data, key) => {
      queryParams.push(`${key}=${data}`);
    });

    if (queryParams.length === 0) {
      return '';
    }

    return '?' + queryParams.join('&');
  }

  protected _buildPostData(requestData: Map<string, any>): any {
    const data = {};

    requestData.forEach((value, key) => {
      data[key] = value;
    });

    return data;
  }
}
