import { Component, OnInit, Input, Output, OnDestroy, Injector } from '@angular/core';
import { BaseGridViewerComponent } from '../../base/base-grid-viewer-component';
import { MarkdownViewerGridWorkflowService } from '../../../../services/display/grid/viewers/markdown-viewer/markdown-viewer-grid-workflow.service';


@Component({
  selector: 'app-display-item-markdown-viewer',
  templateUrl: './markdown-viewer.component.html',
  styleUrls: ['./markdown-viewer.component.scss']
})
export class DisplayItemMarksdownViewerComponent extends BaseGridViewerComponent {

  constructor(
    injector: Injector,
    private markdownViewerGridWorkflowService: MarkdownViewerGridWorkflowService,
  ) {
    super(injector);
  }

  onLoad(event: CustomEvent): void {
    this.loadComplete();
    this.markdownViewerGridWorkflowService.loadComplete(false);
  }

  onError(event: CustomEvent): void {
    this.loadComplete();
    this.markdownViewerGridWorkflowService.loadComplete(true);
  }
}
