import { VuCommunicationService } from './vu-communication.service';
import { Injectable, Injector } from '@angular/core';
import { IVuConnection } from './connection/vu-connection.interfaces';
import { DispatcherService } from '../dispatcher.service';

@Injectable()
export class VuLoggingService {
  private queue: string[] = [];
  private queueLimit = 1000;
  private sending = false;
  private vuConnection: IVuConnection;
  private dispatcherService: DispatcherService;

  constructor(
    private vuCommunicationService: VuCommunicationService,
    private injector: Injector
  ) {
    this.dispatcherService = this.injector.get(DispatcherService);
    this.vuConnection = vuCommunicationService.vuConnection;
    this.vuConnection.eventConnectionChanged.subscribe((connected: boolean) => this.sendAllMessages());
    this.sendAllMessages();
  }

  writeLogMessage(message: string) {
    if (this.vuConnection && this.vuConnection.isConneсted) {
      this.appendToQueue(message);
      if (!this.sending) {
        this.sendAllMessages();
      }
    } else {
      this.appendToQueue(message);
    }
  }

  private appendToQueue(message: string) {
    if (this.queue.length >= this.queueLimit) {
      this.queue.shift();
    }
    this.queue.push(message);
  }

  private sendAllMessages() {
    if (!this.queue.length ||
      !this.vuConnection.isConneсted) {
      return;
    }
    const messageClone = this.queue;
    this.queue = [];
    this.safeWriteLogMessage(messageClone);
  }

  private safeWriteLogMessage(messages: string[]) {
    if (messages == null || !messages.length) {
      return;
    }

    const scope = this;

    scope.sending = true;
    try {
      this.dispatcherService.vuHttp.writeLogMessages(messages)
        .then(() => {
          scope.sending = false;
          scope.sendAllMessages();
        })
        .catch(() => {
          scope.queue.unshift(...messages);
          scope.sending = false;
        });
    } catch (e) {
      scope.sending = false;
    }
  }
}
