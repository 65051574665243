import { Injectable } from '@angular/core';

import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';
import { DisplayItem } from '../../../../../lib/display/display-item/display-item';
import { MessageService, Message, MessageType } from '../../../../message.service';
import { BaseGridWorkflowService } from '../../base/base-grid-workflow.service';

@Injectable()
export class ProductGridWorkflowService extends BaseGridWorkflowService {

  private messageService: MessageService;

  init(
  ) {
    super.init();
    this.messageService = this.injector.get(MessageService);
  }

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.Product;
  }

  processAction(displayItem: DisplayItem, context: any): boolean {
    const result = super.processAction(displayItem, context);
    if (!result || !displayItem) {
      return false;
    }

    this.messageService.sendMessage(new Message(MessageType.VisualItemLeafSelected, displayItem));
    return true;
  }
}
