import { VuState, MessageView, NonOperationalState, Configuration } from '../../lib/lib';
import { MachineBaseService } from './machine-base.service';
import { NonOperationalService } from '../non-operational.service';
import { MessageType, Message } from '../message.service';
import { LiteTouchTileService } from '../lite/lite-touch-tile.service';

export class MachineNonOperationalService extends MachineBaseService {
  private nonOperationalService: NonOperationalService;
  private isSubscribed = false;
  private nonOperationalState: NonOperationalState = new NonOperationalState();
  private liteTouchTileService: LiteTouchTileService;

  init() {
    super.init();
    this.nonOperationalService = this.injector.get(NonOperationalService);
    this.nonOperationalService.eventStateChanged.subscribe((x: NonOperationalState) => this.onNonOperationalStateChanged(x));
    this.liteTouchTileService = this.injector.get(LiteTouchTileService);
    this.dispatcherService.eventServiceInitialized.subscribe(() => this.eventServiceInitialized());
  }

  private eventServiceInitialized() {
    this.dispatcherService.onConfigurationChangedSubscribe(x => this.onConfigurationChangedSubscribe(x));
  }

  get machineName(): string {
    return 'Machine Non Operational';
  }

  protected getTransitions(): any[] {
    return super.getTransitions(
      { name: 'toPending', from: 'off', to: 'pending' },
      { name: 'toEnabled', from: 'pending', to: 'enabled' },
      { name: 'toMaintenance', from: ['enabled', 'offline', 'noConnection', 'burglary'], to: 'maintenance' },
      { name: 'toBurglary', from: ['enabled', 'maintenance', 'offline', 'noConnection'], to: 'burglary' },
      { name: 'toOffline', from: ['enabled', 'maintenance', 'noConnection', 'burglary'], to: 'offline' },
      { name: 'toNoConnection', from: ['enabled', 'maintenance', 'offline', 'burglary'], to: 'noConnection' },
      { name: 'toService', from: ['enabled', 'maintenance', 'off', 'noConnection', 'burglary'], to: 'service' },
    );
  }

  protected getMethods(): any {
    let scope = this;
    return super.getMethods({
      onToPending: function () {
        scope.eventPending.emit();
      },
      onToMaintenance: function () {
        scope.showMessageScreen('Maintenance Mode',
          `The device is in the maintenance mode. Sorry for the inconveniences.`,
          true);
        if (scope.additionalPropertiesConfigurationService.isLiteMode)
          scope.liteTouchTileService.maintenanceMode();
      },
      onToBurglary: function () {
        scope.showMessageScreen('Burglary',
          `Unauthorized door opening!`);
        if (scope.additionalPropertiesConfigurationService.isLiteMode)
          scope.liteTouchTileService.maintenanceMode();
      },
      onToOffline: function () {
        scope.showMessageScreen('Offline',
          `The device is offline`);
        if (scope.additionalPropertiesConfigurationService.isLiteMode)
          scope.liteTouchTileService.maintenanceMode();
      },
      onToNoConnection: function () {
        scope.showMessageScreen('No Connection',
          `There is connection to the device`);
        if (scope.additionalPropertiesConfigurationService.isLiteMode)
          scope.liteTouchTileService.maintenanceMode();
      },
      onToService: function () {
        // Leaves the applicaton
        window.location.href = '/Service';
      },
    });
  }

  onNonOperationalStateChanged(state: NonOperationalState) {
    this.nonOperationalState = state;
    this.doAsync(() => this.updateState(), 'onNonOperationalStateChanged');
    if (state.vuState.isMaintenanceMode) {
      let text = this.buildText();
      this.log.info(`onVuStateChanged. text: ${text}.`);
      this.dispatcherService.messageViewText(text);
    }
  }

  private showMessageScreen(h1: string, h2: string, showText = false) {
    let mv = new MessageView(h1, h2, showText, this.buildText());
    this.dispatcherService.messageViewUpdate(mv);
    this.router.navigateByUrl(this.additionalPropertiesConfigurationService.isLiteMode ? '/lite-mode-message' : '/message');
  }

  private buildText(): string {
    let state = this.nonOperationalState;
    let reasons = state.vuState.maintenaceModeReasons == null ? null : state.vuState.maintenaceModeReasons.join(', ');
    let violation = state.vuState.minHardwareConfigurationViolation;
    let result = reasons == null || reasons.length === 0 ? '' : `<div>${reasons}</div>`;
    result += violation == null || violation.length === 0 ? '' : `<div>${violation}</div>`;
    return result;
  }

  machineStart() {
    if (this.isInState('pending')) {
      this.machine.toEnabled();
      this.updateState();
    } else {
      this.log.warning(`machineStart not expected here. ${this.state}`);
    }
  }

  get isNonOperational() {
    return this.nonOperationalState.isNonOperational
      || !this.configurationService.configuration.changeDate;
  }

  protected onConfigurationChangedSubscribe(configuration: Configuration) {
    this.updateState();
  }

  updateState() {
    if (!this.isNonOperational) {
      this.machine.toOff();
      return;
    }

    let vuState = this.nonOperationalState.vuState;
    let isConnected = this.nonOperationalState.isConnected;

    if (this.isInState('off')) {
      this.machine.toPending();
      return;
    } else if (this.isInState('pending')) {
      return;
    } else if (vuState.isServiceMode) {
      this.machine.toService();
    } else if (!isConnected && !this.isInState('noConnection')) {
      this.machine.toNoConnection();
    } else if (vuState.isBurglaryMode && isConnected && !this.isInState('burglary')) {
      this.machine.toBurglary();
    } else if (vuState.isOfflineMode && isConnected && !this.isInState('offline')) {
      this.machine.toOffline();
    } else if ((vuState.isMaintenanceMode || !this.configurationService.configuration.changeDate)
       && isConnected && !this.isInState('maintenance')) {
      this.machine.toMaintenance();
    }
  }

  protected getMessages(): MessageType[] {
    return super.getMessages();
  }

  protected onMessage(message: Message): boolean {
    if (super.onMessage(message)) {
      return true;
    }

    switch (message.messageType) {
      default:
        break;
    }

    return false;
  }

  protected logMachine(event: any, message: string) {
    if (event.from !== event.to) {
      super.logMachine(event, message);
    }
  }
}
