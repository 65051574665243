import { Component, Injector } from '@angular/core';
import { BaseGridViewerComponent } from '../../base/base-grid-viewer-component';
import { PdfViewerGridWorkflowService } from '../../../../services/display/grid/viewers/pdf-viewer/pdf-viewer-grid-workflow.service';

@Component({
  selector: 'app-display-item-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css']
})
export class DisplayItemPdfViewerComponent extends BaseGridViewerComponent {

  constructor(
    injector: Injector,
    private pdfViewerGridWorkflowService: PdfViewerGridWorkflowService,
  ) {
    super(injector);
  }

  public pageRendered(event: CustomEvent): void {
    this.loadComplete();
    this.pdfViewerGridWorkflowService.loadComplete();
  }
}
