export class PinState {
  constructor(
    public in0: boolean,
    public in1: boolean,
    public in2: boolean,
    public in3: boolean,
    public in4: boolean,
    public in5: boolean,
  ) {
  }

  static fromOther(other: any) {
    return new PinState(
      other.Pin0,
      other.Pin1,
      other.Pin2,
      other.Pin3,
      other.Pin4,
      other.Pin5,
    );
  }
}
