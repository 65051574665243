import { Injectable, EventEmitter, Injector } from '@angular/core';
import { VuCommunicationService } from '../vu/vu-communication.service';
import { IVuConnection } from '../vu/connection/vu-connection.interfaces';
import { CIOBoardEvent } from '../../lib/cioboard/coiboard-event';

@Injectable()
export class CIOBoardService {
  private vuCommunicationService: VuCommunicationService;
  private vuConnection: IVuConnection;

  stateChange: EventEmitter<CIOBoardEvent> = new EventEmitter<CIOBoardEvent>();

  constructor(
    protected injector: Injector,
  ) {
    this.vuCommunicationService = injector.get(VuCommunicationService);
    this.vuConnection = this.vuCommunicationService.vuConnection;
    this.vuConnection.eventCIOBoardStateChanged.subscribe((cioBoardEvent: CIOBoardEvent) => {
      this.stateChange.emit(cioBoardEvent);
    });
  }
}
